import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import paddingStyle from '../assets/jss/paddingStyle';
import {Grid, makeStyles, useTheme} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    ...paddingStyle(theme),
    root: {
        position: 'relative',
        height: '100%',
    },
    cardHeader: {
        position: 'absolute',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        top: '-15px',
        borderRadius: '5px !important',
        background: theme.palette.secondary.main,
        padding: '.2rem',
        textAlign: 'center',
        fontWeight: 'bold',
        color: theme.palette.white,
    },
    container: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
    },
}));

const CardVehicle = ({
    title = '',
    loading = false,
    skeletonHeight = 100,
    children,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    if (loading) {
        return <Skeleton height={skeletonHeight} style={{transform: 'none !important'}}/>;
    }

    return (
        <Paper
            className={classes.root}
            style={title !== ''
                ? {paddingTop: theme.spacing(2)}
                : null
            }
        >
            {title !== ''
                ? <div className={classes.cardHeader}>
                    {title}
                </div>
                : null
            }
            <Grid container spacing={1} className={classes.container}>
                {!loading ? children : null}
            </Grid>
        </Paper>
    );
}

CardVehicle.propTypes = {
    title: PropTypes.string,
    loading: PropTypes.bool,
    skeletonHeight: PropTypes.number,
    children: PropTypes.any,
}

export default CardVehicle;