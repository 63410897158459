import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import {reducer as authReducer} from './auth';
import {clearUserData} from '../api/api';
import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import {reducer as salesReducer} from './sales';
import {reducer as userReducer} from './user';
import {reducer as utilReducer} from './util';
import {reducer as vehicleComplementaryReducer} from './vehicleComplementary';
import {reducer as vehiclePhotosReducer} from './vehiclePhoto';
import {reducer as vehicleReducer} from './vehicle';
import {reducer as vehiclesReducer} from './vehicles';

const combinedReducer = combineReducers({
    authReducer,
    salesReducer,
    utilReducer,
    userReducer,
    vehiclesReducer,
    vehicleReducer,
    vehicleComplementaryReducer,
    vehiclePhotosReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT'){
        state = undefined;
        clearUserData();
    }

    if (action.type === 'RESET_STORE'){
        state = undefined;
    }

    return combinedReducer(state, action);
}

const persistConfig = {
    key: 'root',
    // The storage which is used here.
    storage: storageSession,
    // Only this reducer will be persisted.
    whitelist: ['userReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
    reducer: persistedReducer,
    // Useful cause the persistedReducer is a function, and Thunk allow putting function in store.
    middleware: [thunk],
});


