import * as R from 'ramda';
import VehicleFolder from '../model/VehicleFolder';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import {transformDataToDisplayableValue} from '../core/dataTransformer';

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState: {
        vehicleFolder: null,
        vehicleFolderLoaded: false,
        // Not used yet nevertheless it is handled.
        vehicleFolderIsLoading: false,
    },
    reducers: {
        setVehicleFolder: (state, action) => {
            return {
                ...state,
                vehicleFolder: action.payload,
                vehicleFolderLoaded: true,
                vehicleFolderIsLoading: true,
            }
        },
        setVehicleFolderIsLoading: (state, action) => {
            return {
                ...state,
                vehicleFolderIsLoading: action.payload,
            }
        },
        removeVehicleFolder: (state) => {
            return {
                ...state,
                vehicleFolder: null,
                vehicleFolderLoaded: false,
                vehicleFolderIsLoading: false,
            }
        },
    },
});

export const {actions, reducer} = vehicleSlice;

export const {
    setVehicleFolder,
    setVehicleFolderIsLoading,
    removeVehicleFolder,
} = actions;

export const vehicleFolderModelSelector = createSelector(
    state => state.vehicleReducer.vehicleFolder,
    vehicleFolder => vehicleFolder
        ? new VehicleFolder(transformDataToDisplayableValue(R.clone(vehicleFolder), false))
        : null
);

