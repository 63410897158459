import {apiGet} from './api';
import {getStore} from '../index';
import {setChoicesUser} from '../redux/user';
import {setServicesEmail, setServicesEmailsIsLoading} from '../redux/util';

export const fetchChoicesUser = () => dispatch => {
    apiGet('/api/members')
        .then(resp => {
            dispatch(setChoicesUser(resp));
        });
}

export const fetchServicesEmail = () => {
    getStore().dispatch(setServicesEmailsIsLoading(true));
    apiGet('/api/services')
        .then(response => {
            getStore().dispatch(setServicesEmail(response));
        })
    ;
}