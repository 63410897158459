import Grid from '@material-ui/core/Grid';
import Loading from './pages/Loading';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import ResetPassword from './pages/ResetPassword';
import Sso from './pages/Sso';
import TablesVehicleSale from './pages/TablesVehicleSale';
import UnknownErrorModal from './components/UnknownErrorModal';
import Vehicle from './pages/Vehicle';
import {Redirect, Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {clearUserData, fetchTokenAndFindForUser} from './api/api';
import {connect} from 'react-redux';
import {fetchServicesEmail} from './api/apiUser';

const App = ({
    tokenLoaded = false,
    user = null,
    servicesEmailLoaded = false,
    criticalError = false,
    dispatch,
}) => {
    const inSsoMode = window.location.href.indexOf('/sso/') > -1;

    useEffect(() => {
        if (!tokenLoaded) {
            if (inSsoMode) {
                const cutPathname = window.location.pathname.substring(5);
                const email = cutPathname.substring(0, cutPathname.indexOf('/'));

                if (!localStorage.getItem('email')
                    || (localStorage.getItem('email') && localStorage.getItem('email') !== email)
                ) {
                    clearUserData();
                    localStorage.setItem('email', email);
                }
            }

            dispatch(fetchTokenAndFindForUser());
        }
    }, [tokenLoaded]);

    useEffect(() => {
        if (!servicesEmailLoaded && user) {
            fetchServicesEmail();
        }
    }, [servicesEmailLoaded, user]);

    return (
        <Grid container direction="column">
            <Grid item>
                <UnknownErrorModal open={criticalError}/>
                {!tokenLoaded
                    ? inSsoMode
                        ? null
                        : <Loading/>
                    : <Router>
                        <Switch>
                            <Route exact path={['/login', '/lost-password']}>
                                <Login/>
                            </Route>
                            <Route exact path={'/reset-password/:token'}>
                                <ResetPassword/>
                            </Route>
                            <Route exact path={'/sso/:email/:token/suivi-vente'}>
                                <Sso route="suivi-vente" tokenLoaded={tokenLoaded} user={user}/>
                            </Route>
                            <PrivateRoute
                                path="/list"
                                render={({location}) => <TablesVehicleSale location={location} user={user}/>}
                            />
                            <PrivateRoute path="/vehicle/folder/:vehicleId" exact={true} component={Vehicle}/>
                            <Redirect to="/list/sales/incoming"/>
                        </Switch>
                    </Router>
                }
            </Grid>
        </Grid>
    );
}

App.propTypes = {
    tokenLoaded: PropTypes.bool,
    tokenLoading: PropTypes.bool,
    user: PropTypes.any,
    servicesEmailLoaded: PropTypes.bool,
    criticalError: PropTypes.bool,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    tokenLoaded: state.authReducer.tokenLoaded,
    tokenLoading: state.authReducer.tokenLoading,
    user: state.authReducer.user,
    servicesEmailsLoaded: state.utilReducer.servicesEmailsLoaded,
    criticalError: state.utilReducer.criticalError,
}))(App);
