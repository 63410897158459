import {apiGet, apiPost} from './api';
import {getStore} from '../index';
import {setVehicleComplementary, setVehicleComplementaryIsLoading} from '../redux/vehicleComplementary';
import {setVehicleFolder, setVehicleFolderIsLoading} from '../redux/vehicle';
import {setVehiclePhotos, setVehiclePhotosIsLoading} from '../redux/vehiclePhoto';

export const fetchVehicleData = id => {
    getStore().dispatch(setVehicleFolderIsLoading(true));
    apiGet('/api/vehicle/' + id)
        .then(response => {
            getStore().dispatch(setVehicleFolder(response));

            fetchVehiclePhotos(response['immatriculation']);
        })
    ;
}

export const fetchVehicleComplementaryData = id => {
    getStore().dispatch(setVehicleComplementaryIsLoading(true));
    apiGet('/api/vehicle/complementary/' + id)
        .then(response => {
            getStore().dispatch(setVehicleComplementary(response));
        })
    ;
}

export const fetchVehiclePhotos = registrationNumber => {
    getStore().dispatch(setVehiclePhotosIsLoading(true));
    apiPost('/api/vehicle/photos/' + registrationNumber, {resolution: 'med'})
        .then(response => {
            getStore().dispatch(setVehiclePhotos(response))
        })
    ;
}