import CustomLink from '../components/Custom/CustomLink';
import Header from '../components/Header';
import PropTypes from 'prop-types';
import React from 'react';
import SaleIncomingVehicles from '../components/Tables/SaleIncomingVehicles';
import SalePastVehicles from '../components/Tables/SalePastVehicles';
import SalesIncoming from '../components/Tables/SalesIncoming';
import SalesPast from '../components/Tables/SalesPast';
import TabWarning from '../components/Custom/TabWarning';
import TabsWarning from '../components/Custom/TabsWarning';
import VehiclesBlocked from '../components/Tables/VehiclesBlocked';
import VehiclesStock from '../components/Tables/VehiclesStock';
import {Route, Switch} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: theme.spacing(3),
    },
}));

function a11yProps(index, path) {
    return {
        component: CustomLink,
        to: path,
        value: path,
    };
}


const TablesVehicleSale = ({
    user = null,
    location,
}) => {
    const mainRoutes = ['/list/sales/incoming', '/list/sales/past', '/list/vehicles/blocked', '/list/vehicles/stock'];
    const classes = useStyles();
    const prevLink = useSelector(state => state.userReducer.prevLink);
    const shouldIUsePrevLink = !mainRoutes.includes(location.pathname);

    const cotationPath = process.env.REACT_APP_SPARK_URL + '/authenticate_sso?email=' +
        user.email + '&hash=' + user.email_token + '&url=_cotation_index'

    return (
        <>
            <Header/>
            <TabsWarning
                value={shouldIUsePrevLink ? prevLink : location.pathname}>
                <TabWarning label="Suivi vente" {...a11yProps(0, '/list/sales/incoming')}/>
                <TabWarning label="Résultats de vente" {...a11yProps(1, '/list/sales/past')}/>
                <TabWarning label="Dossier en attente" {...a11yProps(2, '/list/vehicles/blocked')}/>
                <TabWarning label="Stock de véhicules" {...a11yProps(3, '/list/vehicles/stock')}/>
                <TabWarning label="Cotation" {...a11yProps(4, cotationPath)} />
            </TabsWarning>
            <div className={classes.tableContainer}>
                <Switch>
                    <Route path="/list/sales/incoming" render={() => <SalesIncoming/>}/>
                    <Route path="/list/sales/past" render={() => <SalesPast/>}/>
                    <Route path="/list/vehicles/blocked" render={() => <VehiclesBlocked/>}/>
                    <Route path="/list/vehicles/stock" render={() => <VehiclesStock/>}/>
                    <Route path="/list/vehicles/sales/incoming/:saleId" render={() => <SaleIncomingVehicles/>}/>
                    <Route path="/list/vehicles/sales/past/:saleId" render={() => <SalePastVehicles/>}/>
                </Switch>
            </div>
        </>
    );
}

TablesVehicleSale.propTypes = {
    user: PropTypes.any,
    location: PropTypes.object.isRequired,
}

export default TablesVehicleSale;
