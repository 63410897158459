import {getStore} from '../index';
import {setCriticalError} from '../redux/util';
import {setTokenLoaded, setTokenLoading, setUser} from '../redux/auth';

const ClientOauth2 = require('client-oauth2');

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const EXPIRES_KEY = 'expires';
export const HTTP_ERROR_500 = 'http_error_500';
export const HTTP_ERROR = 'http_error';
export const UNKNOWN_ERROR_MESSAGE = 'Une erreur inconnue est survenue, merci de réssayer plus tard.';

const appAuth = new ClientOauth2({
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    clientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    accessTokenUri: process.env.REACT_APP_API_URL + '/token',
    authorizationUri: process.env.REACT_APP_API_URL + '/authorize',
    scopes: [],
});

const getClientData = () => {
    return {
        accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
        refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
        expires: localStorage.getItem(EXPIRES_KEY),
    }
}

export const clearUserData = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(EXPIRES_KEY);
}

export const fetchTokenAndFindForUser = () => dispatch => {
    console.log('FETCH TOKEN AND FIND FOR USER')
    let clientData = getClientData();
    dispatch(setTokenLoading(true));

    if (!clientData.refreshToken) {
        console.log('NO REFRESH TOKEN')
        dispatch(setTokenLoaded(true));

        return;
    }

    //(Has refresh token = user) Reload token if needed and load user
    if (clientData.accessToken && clientData.refreshToken && new Date(clientData.expires) < new Date()) {
        console.log('USING REFRESH TOKEN')
        refreshToken(clientData.accessToken, clientData.refreshToken)
            .then(() => {
                return fetchUser()
            })
            .then(user => {
                dispatch(setUser(user));
                dispatch(setTokenLoading(false));
                dispatch(setTokenLoaded(true));
            })
            .catch(() => {
                clearUserData();
                console.log('ERROR REFRESH TOKEN');
                dispatch(fetchTokenAndFindForUser());
            });

        return;
    }

    // Get user
    if (clientData.accessToken && new Date(clientData.expires) > new Date()) {
        console.log('USING ACCESS TOKEN')
        fetchUser()
            .then(user => {
                dispatch(setUser(user));
                dispatch(setTokenLoading(false))
                dispatch(setTokenLoaded(true));
            })
            .catch(() => {
                console.log('ERROR GET USER');
                clearUserData();
                dispatch(fetchTokenAndFindForUser());
            });
    }
};

const refreshToken = (accessToken, refreshToken) => {
    console.log('REFRESH TOKEN')
    let token = appAuth.createToken(accessToken, refreshToken);
    return token.refresh()
        .then(token => {
            localStorage.setItem(ACCESS_TOKEN_KEY, token.accessToken);
            localStorage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
            localStorage.setItem(EXPIRES_KEY, token.expires);
        }).catch(e => console.log('------ ERROR REFRESH TOKEN ------- ' + e));
}

export const fetchUserToken = (email, password, dispatch) => {
    console.log('FETCH USER TOKEN')
    return appAuth.owner.getToken(email, password)
        .then(token => {
            localStorage.setItem(ACCESS_TOKEN_KEY, token.accessToken);
            localStorage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
            localStorage.setItem(EXPIRES_KEY, token.expires);
            dispatch(setTokenLoaded(true));
        })
        .then(() => {
            return fetchUser();
        })
        .then(user => {
            dispatch(setUser(user));
            return user;
        });
}

export const fetchUserEmailToken = (email, token, dispatch) => {
    console.log('FETCH USER EMAIL TOKEN')
    return appAuth.owner.getToken(email, 'passwordpardefaut',{
        body: {
            grant_type: 'email_token',
            token,
        },
    })
        .then(token => {
            localStorage.setItem(ACCESS_TOKEN_KEY, token.accessToken);
            localStorage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
            localStorage.setItem(EXPIRES_KEY, token.expires);
            dispatch(setTokenLoaded(true));
        })
        .then(() => {
            return fetchUser();
        })
        .then(user => {
            dispatch(setUser(user));
            return user;
        });
}

export const downloadExcel = (title, data) => {
    apiPost('/api/export_excel', {title: title, data: data}, true)
        .then(resp => resp.blob())
        .then(content => {
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = window.URL.createObjectURL(content);
            a.download = 'export.xls';
            a.click();
        })
        .catch(e => {
            console.log(e);
        })
}

export const fetchUser = () => {
    return apiGet('/api/connected_user')
        .then(resp => {
            return resp;
        });
}

export const apiGet = (url, returnResponse = false) => {
    return apiFetch('get', url, false, returnResponse)
};

export const apiDelete = (url, returnResponse = false) => {
    return apiFetch('delete', url, false, returnResponse)
};

export const apiPost = (url, data, returnResponse = false) => {
    return apiFetch('post', url, data, returnResponse)
};

const apiFetch = (method, url, data, returnResponse = false) => {
    let clientData = getClientData();

    let init = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + clientData.accessToken,
        },
    }

    if (data && typeof data !== 'undefined') {
        init.body = JSON.stringify(data)
    }

    return fetch(process.env.REACT_APP_API_URL + url, init)
        .then(response => {
            if (response.status === 401) {
                window.location.reload();
                return;
            }

            if (response.status === 403){
                getStore().dispatch({type: 'USER_LOGOUT'});
                return;
            }

            // Others api errors
            if (response.status >= 400 && response.status <=500){
                getStore().dispatch(setCriticalError(true));
                return {};
            }

            if (response.status <= 200 && response.status < 300) {
                return returnResponse ? response : response.json();
            }
        });
}
