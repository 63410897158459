import Box from '@material-ui/core/Box';
import CardVehicle from '../components/CardVehicle';
import Chip from '@material-ui/core/Chip';
import DropdownMemo from '../components/DropdownMemo';
import DropdownPartlyCarBody from '../components/DropdownPartlyCarBody';
import FieldVehicle from '../components/FieldVehicle';
import Header from '../components/Header';
import ImageViewer from 'react-simple-image-viewer';
import PartlyCarBody from '../components/PartlyCarBody';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import ServicesEmailMenu from '../components/ServicesEmailMenu';
import Tooltip from '@material-ui/core/Tooltip';
import VehicleActionButton from '../components/VehicleActionButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import paddingStyle from '../assets/jss/paddingStyle';
import {CircularProgress, Grid, IconButton, Typography, useTheme} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {connect, useSelector} from 'react-redux';
import {equipmentsSortByWeight, removeVehicleComplementary} from '../redux/vehicleComplementary';
import {fetchVehicleComplementaryData, fetchVehicleData} from '../api/apiVehicle';
import {removeVehicleFolder, vehicleFolderModelSelector} from '../redux/vehicle';
import {removeVehiclePhotos} from '../redux/vehiclePhoto';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    ...paddingStyle(theme),
    paper: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    photoWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const BlockHeader = ({vehicleFolder}) =>
    <>
        <FieldVehicle value={vehicleFolder.docNum} label={'Réf. int.'} gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.plateNumber} label={'Immatriculation'} gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.storageParkCity} label={'Entrée sur parc'} gridItemSize={3}/>
        {/* TODO : StorageParkCity doesn't perfectly fit with 'Salle de vente', need to found the right data */}
        <FieldVehicle value={vehicleFolder.storageParkCity} label={'Salle de vente'} gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.cardNameSeller} label={'Vendeur'} gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.ownerDebtor} label={'Propriétaire Debiteur'} gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.deliveryDate} label={'Date livraison'} gridItemSize={3}/>
        {/* This blank one is used to render properly the data */}
        <FieldVehicle gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.referenceSeller} label={'Réf. vendeur'} gridItemSize={3}/>
        {/* This blank one is used to render properly the data */}
        <FieldVehicle gridItemSize={3}/>
        <FieldVehicle
            value={vehicleFolder.createdDate + ' (nb. jours : ' + vehicleFolder.vehicleDaysAmount + ')'}
            label={'Date de création'}
            gridItemSize={4}
        />
    </>
    


BlockHeader.propTypes = {
    vehicleFolder: PropTypes.object.isRequired,
}

const BlockSubHeader = ({vehicleFolder}) =>
    <>
        <FieldVehicle value={vehicleFolder.status} label={'Statut'} gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.saleDate} label={'Date de vente'} gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.orderNumber + ' ' + vehicleFolder.orderNumberComplementary} label={'Num. ordre aff.'} gridItemSize={3}/>
        {/* This blank one is used to render properly the data */}
        <FieldVehicle gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.subStatus} label={'Sous statut'} gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.saleHour} label={'Heure de vente'} gridItemSize={3}/>
        {/* This blank one is used to render properly the data */}
        <FieldVehicle gridItemSize={3}/>
        <FieldVehicle gridItemSize={3}/>
        <FieldVehicle value={vehicleFolder.countNumber} label={'N° décompte'} gridItemSize={3}/>
    </>

BlockSubHeader.propTypes = {
    vehicleFolder: PropTypes.object.isRequired,
}

const BlockVehicle = ({vehicleFolder}) =>
    <>
        <FieldVehicle input value={vehicleFolder.type} label={'Genre'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.brand} label={'Marque'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.commercialType} label={'Type com.'} gridItemSize={12}/>
        <FieldVehicle input value={vehicleFolder.registeredAt.format('DD/MM/YYYY')} label={'MEC'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.manufactureYear} label={'Année'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.statementKilometers} label={'Km. déclaré'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.actualMileage} label={'Km. réel'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.color} label={'Couleur'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.energy} label={'Energie'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.tvaRecoverable} label={'TVA'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.drivingSchool} label={'Auto école'} gridItemSize={6}/>
    </>

BlockVehicle.propTypes = {
    vehicleFolder: PropTypes.object.isRequired,
}

const BlockRegistrationCard = ({vehicleFolder}) =>
    <>
        <FieldVehicle input value={vehicleFolder.carBody} label={'Carrosserie'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.seats} label={'Places'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.mineType} label={'Type mine'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.serialNumber} label={'N° de série'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.energy} label={'Energie'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.power} label={'Puissance'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.hasTechnicalInspection} label={'Carte grise'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.plateNumberCertificate} label={'Certif. immat.'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.plateNumberCertificateFollowUp} label={'Suivi certif. immat.'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.dateReceiptGreyCard} label={'Date réception CG'} gridItemSize={6}/>
    </>

BlockRegistrationCard.propTypes = {
    vehicleFolder: PropTypes.object.isRequired,
}

const BlockState = ({vehicleFolder}) =>
    <>
        <FieldVehicle label={'Avaries'} gridItemSize={12}>
            <ul>
                {vehicleFolder.damage.split('/').map((damage, key) => damage ? <li key={key}><strong>{damage}</strong></li> : null)}
            </ul>
        </FieldVehicle>
        <FieldVehicle input value={vehicleFolder.maintenanceFollowUp} label={'Suivi Ent.'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.statusTechnicalControl} label={'CT'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.hasMaintencanceBook} label={'Carnet'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.hasKey} label={'Clé'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.missingSpareWheel === 'O' ? 'Oui' : vehicleFolder.missingSpareWheel} label={'Roue de secours abs.'} gridItemSize={6}/>
        <FieldVehicle input value={vehicleFolder.hasBatteryProblem} label={'Pb. batterie'} gridItemSize={6}/>
    </>

BlockState.propTypes = {
    vehicleFolder: PropTypes.object.isRequired,
}

const BlockQuotation = ({vehicleFolder}) =>
    <>
        <FieldVehicle input value={vehicleFolder.visualRating} label={'Note visuelle'} gridItemSize={4}/>
        <FieldVehicle input value={vehicleFolder.interiorRating} label={'Note intérieur'} gridItemSize={4}/>
        <FieldVehicle input value={vehicleFolder.expertiseRating} label={'Note expertise'} gridItemSize={4}/>
        <FieldVehicle input value={vehicleFolder.state} label={'Etat'} gridItemSize={12}/>
        <FieldVehicle input value={vehicleFolder.sellerObservation} label={'Obs.'} gridItemSize={12}/>
        <FieldVehicle input value={vehicleFolder.argusModel} label={'Type argus'} gridItemSize={12}/>
        <FieldVehicle input value={vehicleFolder.lastCotation} label={'Cotation le'} gridItemSize={12}/>
        <FieldVehicle input value={vehicleFolder.cotePerso} label={'Cote perso.'} gridItemSize={4}/>
        <FieldVehicle input value={vehicleFolder.reservePrice} label={'Réserve'} gridItemSize={4}/>
        <FieldVehicle
            input
            value={
                vehicleFolder.personalQuotingReserveRatio
                    ? vehicleFolder.personalQuotingReserveRatio + ' %'
                    : ''
            }
            label={'Réserve / Cote perso.'}
            gridItemSize={4}
        />
        <FieldVehicle input value={vehicleFolder.coteKilo} label={'Cote km.'} gridItemSize={4}/>
        <FieldVehicle input value={vehicleFolder.estimationPrice} label={'Estimation'} gridItemSize={4}/>
        <FieldVehicle
            input
            value={
                vehicleFolder.mileageQuotingReserveRatio
                    ? vehicleFolder.mileageQuotingReserveRatio + ' %'
                    : ''
            }
            label={'Réserve / Cote Km. '}
            gridItemSize={4}
        />
        <FieldVehicle input value={vehicleFolder.vehicleAge} label={'Age'} gridItemSize={4}/>
        <FieldVehicle input value={vehicleFolder.originalPriceArgus} label={'Neuf'} gridItemSize={4}/>
        <FieldVehicle input value={vehicleFolder.repairCosts} label={'FRE'} gridItemSize={4}/>
    </>

BlockQuotation.propTypes = {
    vehicleFolder: PropTypes.object.isRequired,
}

const BlockSale = ({vehicleFolder}) =>
    <>
        <FieldVehicle input value={vehicleFolder.reservePriceMinusOne} label={'Rés -1'} gridItemSize={12}/>
        <FieldVehicle input value={vehicleFolder.numberOfPassage} label={'Nb. passages'} gridItemSize={12}/>
        {vehicleFolder.saleStatus === 'ADJ'
            ? <>
                <FieldVehicle input value={vehicleFolder.adjudicationPrice} label={'Prix ADJ'} gridItemSize={12}/>
                <FieldVehicle
                    input
                    value={
                        vehicleFolder.personalQuotingAdjudicationRatio
                            ? vehicleFolder.personalQuotingAdjudicationRatio + ' %'
                            : ''
                    }
                    label={'%C.P'}
                    gridItemSize={12}
                />
                <FieldVehicle
                    input
                    value={
                        vehicleFolder.mileageQuotingAdjudicationRatio
                            ? vehicleFolder.mileageQuotingAdjudicationRatio + ' %'
                            : ''
                    }
                    label={'%C.K'}
                    gridItemSize={12}
                />
            </>
            : null
        }
        {vehicleFolder.saleStatus === 'RET'
            ? <FieldVehicle input value={vehicleFolder.revocationPrice} label={'Prix RET'} gridItemSize={12}/>
            : null
        }
    </>

BlockSale.propTypes = {
    vehicleFolder: PropTypes.object.isRequired,
}

const Vehicle = ({
    vehicleFolderLoaded,
    vehicleComplementary,
    vehicleComplementaryLoaded,
    vehiclePhotos,
    vehiclePhotosProduct,
    vehiclePhotosDamages,
    vehiclePhotosMisc,
    vehiclePhotoPanorama,
    vehiclePhotosLoaded,
    dispatch,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const {vehicleId} = useParams();
    const vehicleFolder = useSelector(vehicleFolderModelSelector);
    const equipments = useSelector(equipmentsSortByWeight);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    useEffect(() => {
        if (!vehicleFolderLoaded) {
            fetchVehicleData(vehicleId);
        }

        return () => {
            if (vehicleFolderLoaded) {
                dispatch(removeVehicleFolder());
                dispatch(removeVehiclePhotos());
                dispatch(removeVehicleComplementary());
            }
        }
    }, [vehicleFolderLoaded]);

    useEffect(() => {
        if (!vehicleComplementaryLoaded) {
            fetchVehicleComplementaryData(vehicleId);
        }

        return () => {
            if (vehicleFolderLoaded) {
                dispatch(removeVehicleComplementary());
            }
        }
    }, [vehicleComplementaryLoaded]);

    // Remove & reload vehicle when id change with VehicleSwipper.js
    useEffect(() => {
        if (vehicleFolderLoaded) {
            dispatch(removeVehicleFolder());
        }
    }, [vehicleId]);

    // Only to reset the iframeLoaded state cause if the page is reload (F5 / Cmd + R) the state isn't reload too.
    useEffect(() => {
        return () => {
            setIframeLoaded(false);
        }
    }, [true]);

    return (
        <>
            {isViewerOpen && (
                <ImageViewer
                    src={vehiclePhotos}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                />
            )}
            <Header/>
            <Box p={2}>
                <Grid container justifyContent="flex-end" style={{marginBottom: '.5rem'}}>
                    {vehicleFolderLoaded && vehicleFolder['urlSE'] !== ''
                        ? <Tooltip placement="top" title="Suivi entretien">
                            <IconButton
                                target="_blank"
                                href={vehicleFolder['urlSE']}
                                style={{paddingTop: '.5rem', paddingBottom: '.5rem'}}
                            >
                                SE
                            </IconButton>
                        </Tooltip>
                        : null
                    }
                    {vehicleFolderLoaded && vehicleFolder['urlCT'] !== ''
                        ? <Tooltip placement="top" title="Contrôle technique">
                            <IconButton
                                target="_blank"
                                href={vehicleFolder['urlCT']}
                                style={{paddingTop: '.5rem', paddingBottom: '.5rem'}}
                            >
                                CT
                            </IconButton>
                        </Tooltip>
                        : null
                    }
                    {vehicleFolderLoaded && vehicleFolder['urlFE'] !== ''
                        ?  <Tooltip placement="top" title="Fiche essai">
                            <IconButton
                                target="_blank"
                                href={vehicleFolder['urlFE']}
                                style={{paddingTop: '.5rem', paddingBottom: '.5rem'}}
                            >
                                FE
                            </IconButton>
                        </Tooltip>
                        : null
                    }
                    <VehicleActionButton loaded={vehicleComplementaryLoaded}>
                        <DropdownPartlyCarBody>
                            <PartlyCarBody vehicle={vehicleComplementary}/>
                        </DropdownPartlyCarBody>
                    </VehicleActionButton>
                    <VehicleActionButton loaded={vehicleFolderLoaded}>
                        <ServicesEmailMenu
                            cardNameSeller={vehicleFolderLoaded ? vehicleFolder.cardNameSeller : ''}
                            vehiclePlateNumber={vehicleFolderLoaded ? vehicleFolder.plateNumber : ''}
                            vehicleDocNum={vehicleFolderLoaded ? vehicleFolder.docNum : ''}
                            subStatus={vehicleFolderLoaded ? vehicleFolder.subStatus : ''}
                            status={vehicleFolderLoaded ? vehicleFolder.status : ''}
                        />
                    </VehicleActionButton>
                    <VehicleActionButton loaded={vehicleFolderLoaded}>
                        <DropdownMemo>
                            {vehicleFolderLoaded && vehicleFolder['externalMemo']
                                ? vehicleFolder['externalMemo']
                                : 'Aucun memo'
                            }
                        </DropdownMemo>
                    </VehicleActionButton>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={9} container spacing={2}>
                        <Grid item xs={12}>
                            <CardVehicle loading={!vehicleFolderLoaded}>
                                {vehicleFolderLoaded
                                    ? <BlockHeader vehicleFolder={vehicleFolder}/>
                                    : null
                                }
                            </CardVehicle>
                        </Grid>
                        <Grid item xs={12}>
                            <CardVehicle loading={!vehicleFolderLoaded}>
                                {vehicleFolderLoaded
                                    ? <BlockSubHeader vehicleFolder={vehicleFolder}/>
                                    : null
                                }
                            </CardVehicle>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} className={classes.photoWrapper}>
                        {vehiclePhotosLoaded
                            ? <>
                                {vehiclePhotos.iframe
                                    ? <>
                                        {iframeLoaded
                                            ? null
                                            : <Grid
                                                container
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{height: '18rem', overflow: 'hidden'}}
                                            >
                                                <Grid item>
                                                    <CircularProgress/>
                                                </Grid>
                                            </Grid>
                                        }
                                        <iframe
                                            style={{
                                                height: '18rem',
                                                borderRadius: '5px',
                                                display: iframeLoaded ? '' : 'none',
                                            }}
                                            scrolling="no"
                                            width="100%"
                                            onLoad={() => {setIframeLoaded(true)}}
                                            height="100%"
                                            frameBorder="0"
                                            allowFullScreen
                                            src={'https://tea.serve3sixty.com/s3s/iframe?k='
                                                + vehiclePhotos.iframe + '&res=low-med&v=true&n=s'
                                            }
                                        />
                                    </>
                                    : <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{height: '18rem', overflow: 'hidden'}}
                                    >
                                        <Grid item>
                                            <i>Aucune photos trouvées pour ce véhicle</i>
                                        </Grid>
                                    </Grid>
                                }
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    margin: theme.spacing(1),
                                }}>
                                    <Chip
                                        size="small"
                                        color="primary"
                                        label={
                                            vehiclePhotosProduct.length
                                            + ' studio' +
                                            (vehiclePhotosProduct.length - 1 > 1 ? 's' : '')
                                        }
                                    />
                                    <Chip
                                        size="small"
                                        color="secondary"
                                        label={
                                            vehiclePhotosMisc.length
                                            + ' détail' +
                                            (vehiclePhotosMisc.length - 1 > 1 ? 's' : '')
                                        }
                                    />
                                </div>
                            </>
                            : <>
                                <Skeleton width={'100%'} height={150}/>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: theme.spacing(1),
                                }}>
                                    <Skeleton width={'20%'}/>
                                    <Skeleton width={'20%'}/>
                                </div>
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Grid container spacing={2} className={classes.mt1}>
                            <Grid item xs={5}>
                                <CardVehicle loading={!vehicleFolderLoaded} title={'Véhicule'}>
                                    {vehicleFolderLoaded
                                        ? <BlockVehicle vehicleFolder={vehicleFolder}/>
                                        : null
                                    }
                                </CardVehicle>
                            </Grid>
                            <Grid item xs={7}>
                                <CardVehicle loading={!vehicleFolderLoaded} title={'Carte grise'}>
                                    {vehicleFolderLoaded
                                        ? <BlockRegistrationCard vehicleFolder={vehicleFolder}/>
                                        : null
                                    }
                                </CardVehicle>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.mt3}>
                            <Grid item xs={5}>
                                <CardVehicle loading={!vehicleFolderLoaded} title={'Suivi'}>
                                    {vehicleFolderLoaded
                                        ? <BlockState vehicleFolder={vehicleFolder}/>
                                        : null
                                    }
                                </CardVehicle>
                            </Grid>
                            <Grid container item xs={7}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <CardVehicle loading={!vehicleFolderLoaded} title={'Cotation'}>
                                            {vehicleFolderLoaded
                                                ? <BlockQuotation vehicleFolder={vehicleFolder}/>
                                                : null
                                            }
                                        </CardVehicle>
                                    </Grid>
                                    <Grid item xs={3} style={{marginRight: '0'}}>
                                        <CardVehicle loading={!vehicleFolderLoaded} title={'Vente'}>
                                            {vehicleFolderLoaded
                                                ? <BlockSale vehicleFolder={vehicleFolder}/>
                                                : null
                                            }
                                        </CardVehicle>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container spacing={2} className={classes.mt1}>
                            <Grid item xs={12}>
                                <CardVehicle loading={!vehicleComplementaryLoaded} title={'Équipements'} skeletonHeight={255}>
                                    <Box p={1}>
                                        {vehicleComplementaryLoaded && equipments.length === 0
                                            ? <Typography align="center">Aucun équipements</Typography>
                                            : null
                                        }
                                        {vehicleComplementaryLoaded && equipments.length
                                            ? equipments.map((equipment, index) =>
                                                <Typography
                                                    key={index}>- {equipment['longName'] !== null
                                                        ? equipment['longName']
                                                        : equipment['shortName'] !== null
                                                            ? equipment['shortName']
                                                            : <i>{equipment['code']}</i>
                                                    }
                                                </Typography>
                                            )
                                            : null
                                        }
                                    </Box>
                                </CardVehicle>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.mt3} spacing={2}>
                    {vehiclePhotosLoaded && vehiclePhotosProduct.length
                        ? <Grid item xs={6}>
                            <CardVehicle loading={!vehicleFolderLoaded} title="Photos studio">
                                {vehiclePhotosProduct.map((photo, index) => {
                                    return <img
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100px',
                                            cursor: 'pointer',
                                            margin: '0.5rem',
                                        }}
                                        src={photo}
                                        onClick={() => openImageViewer(index)}
                                        key={index}
                                        alt=""
                                    />
                                })}
                            </CardVehicle>
                        </Grid>
                        : null
                    }
                    {vehiclePhotosLoaded && vehiclePhotosMisc.length
                        ? <Grid item xs={6}>
                            <CardVehicle loading={!vehicleFolderLoaded} title="Photos détails">
                                {vehiclePhotosMisc.map((photo, index) => {
                                    return <img
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100px',
                                            cursor: 'pointer',
                                            margin: '0.5rem',
                                        }}
                                        src={photo}
                                        onClick={() => openImageViewer(index + vehiclePhotosProduct.length)}
                                        key={index}
                                        alt=""
                                    />
                                })}
                            </CardVehicle>
                        </Grid>
                        : null
                    }
                    {vehiclePhotosLoaded && vehiclePhotosDamages.length
                        ? <Grid item xs={6}>
                            <CardVehicle loading={!vehicleFolderLoaded} title="Photos dommages">
                                {vehiclePhotosDamages.map((photo, index) => {
                                    return <img
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100px',
                                            cursor: 'pointer',
                                            margin: '0.5rem',
                                        }}
                                        src={photo}
                                        onClick={() => openImageViewer(index + vehiclePhotosProduct.length + vehiclePhotosMisc.length)}
                                        key={index}
                                        alt=""
                                    />
                                })}
                            </CardVehicle>
                        </Grid>
                        : null
                    }
                    {vehiclePhotosLoaded && vehiclePhotoPanorama
                        ? <Grid item xs={6}>
                            <CardVehicle loading={!vehicleFolderLoaded} title="Photos panorama">
                                <iframe
                                    width="100%"
                                    height="95%"
                                    frameBorder="0"
                                    scrolling="no"
                                    allowFullScreen
                                    src={'https://tea.serve3sixty.com/s3s/iframe?k='
                                        + vehiclePhotoPanorama['iframe_encrypt'] + '&res=low-med&v=true&n=s'
                                    }
                                >
                                </iframe>
                            </CardVehicle>
                        </Grid>
                        : null
                    }
                </Grid>
            </Box>
        </>
    );
}

Vehicle.propTypes = {
    vehicleFolderLoaded: PropTypes.bool,
    vehicleFolderIsLoading: PropTypes.bool,
    vehicleComplementary: PropTypes.object,
    vehicleComplementaryLoaded: PropTypes.bool,
    vehiclePhotos: PropTypes.object,
    vehiclePhotosProduct: PropTypes.array,
    vehiclePhotosMisc: PropTypes.array,
    vehiclePhotosDamages: PropTypes.array,
    vehiclePhotoPanorama: PropTypes.any,
    vehiclePhotosLoaded: PropTypes.object,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    vehicleFolderLoaded: state.vehicleReducer.vehicleFolderLoaded,
    vehicleFolderIsLoading: state.vehicleReducer.vehicleFolderIsLoading,
    vehicleComplementary: state.vehicleComplementaryReducer.vehicleComplementary,
    vehicleComplementaryLoaded: state.vehicleComplementaryReducer.vehicleComplementaryLoaded,
    vehiclePhotos: state.vehiclePhotosReducer.vehiclePhotos,
    vehiclePhotosProduct: state.vehiclePhotosReducer.vehiclePhotosProduct,
    vehiclePhotosMisc: state.vehiclePhotosReducer.vehiclePhotosMisc,
    vehiclePhotosDamages: state.vehiclePhotosReducer.vehiclePhotosDamages,
    vehiclePhotoPanorama: state.vehiclePhotosReducer.vehiclePhotoPanorama,
    vehiclePhotosLoaded: state.vehiclePhotosReducer.vehiclePhotosLoaded,
}))(Vehicle);