import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Breadcrumb from './Breadcrumb';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import VehicleSwiper from './VehicleSwipper';
import displayStyle from '../assets/jss/displayStyle';
import {AccountCircle} from '@material-ui/icons';
import {ClickAwayListener, Grid, Toolbar, Tooltip, Typography, Zoom, makeStyles} from '@material-ui/core';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {ROLE_SELLER_FAMILY, selectUser} from '../redux/user';
import {Skeleton} from '@material-ui/lab';
import {connect, useDispatch} from 'react-redux';
import {fetchChoicesUser} from '../api/apiUser';
import {red} from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    ...displayStyle(theme),
    appBar: {
        background: '#f5f5f5',
    },
    headerRow: {
        borderBottom: '1px solid #ccc',
    },
    justifyContentEnd: {
        justifyContent: 'end',
    },
    accountButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'Coda Caption, sans-serif;',
        fontSize: '2rem',
        color: '#000',
    },
    logoGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        maxWidth: '100%',
    },
    userChoiceGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    userChoiceText: {
        color: theme.palette.primary.dark,
        marginLeft: theme.spacing(2),
    },
    subHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    link: {
        color: 'black',
        textDecoration: 'none !important',
    },
    linkList: {
        paddingBottom: 0,
    },
    disconnectList: {
        paddingTop: 0,
    },
}));

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: 'rgba(43,60,180,0.8)',
    },
    arrow: {
        color: 'rgba(43,60,180,0.8)',
    },
}))(Tooltip);

const UserChoiceMenu = ({
    selectedUser = null,
    choicesUser = [],
    classes,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    let location = useLocation();
    let history = useHistory();
    let dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const addUser = user => {
        handleClose();
        dispatch(selectUser(user, history, location));
    }

    const [open, setOpen] = useState(!selectedUser);

    const handleTooltipClose = () => {
        selectedUser ? setOpen(false) : setOpen(true);
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <HtmlTooltip
                    arrow
                    TransitionComponent={Zoom}
                    open={open}
                    placement="right"
                    title={<Typography variant="body1">Veuillez sélectionner un vendeur</Typography>}
                >
                    <Button onClick={handleClick}>
                        <Avatar>
                            <AccountCircle/>
                        </Avatar>
                        <Typography variant="body1" className={classes.userChoiceText}>
                            {!selectedUser ? 'Sélectionner un vendeur' : selectedUser['cardName']}
                        </Typography>
                    </Button>
                </HtmlTooltip>
            </ClickAwayListener>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {choicesUser.map((user, index) =>
                    <MenuItem key={index} onClick={() => addUser(user)} value={user['cardCode']}>
                        {user['cardName']}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}

UserChoiceMenu.propTypes = {
    selectedUser: PropTypes.any,
    choicesUser: PropTypes.array,
    classes: PropTypes.any,
}

const Header = ({
    user = null,
    selectedUser = null,
    choicesUser = [],
    choicesUserLoaded = false,
    hasBreadcrumb = false,
    dispatch,
}) => {
    const classes = useStyles();

    let location = useLocation(),
        hasVehicleList = location.pathname.includes('/vehicle/folder');

    useEffect(() => {
        if (!choicesUserLoaded) {
            dispatch(fetchChoicesUser());
        }
    }, [choicesUserLoaded])

    return (
        <>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.headerRow}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid xs={2} item className={classes.userChoiceGrid}>
                            {user.roles.includes(ROLE_SELLER_FAMILY)
                                ? (choicesUserLoaded
                                    ? <UserChoiceMenu
                                        choicesUser={choicesUser}
                                        selectedUser={selectedUser}
                                        classes={classes}/>
                                    : <Skeleton style={{transform: 'none'}} width="200px" height="45px"/>
                                )
                                : null
                            }
                        </Grid>
                        <Grid xs={2} item>
                            <Link to={'/'}>
                                <img src="/logo.png" className={classes.logo} alt="Encheres VO"/>
                            </Link>
                        </Grid>
                        <Grid xs={2} item style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button
                                style={{color: red[700]}}
                                startIcon={<PowerSettingsNewIcon/>}
                                onClick={() => dispatch({type: 'USER_LOGOUT'})}
                            >
                                Logout
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {hasVehicleList
                ? <div className={classes.subHeader}>
                    {hasBreadcrumb ? <Breadcrumb/> : null}
                    {hasVehicleList ? <VehicleSwiper/> : null}
                </div>
                : null
            }
        </>
    );
}

Header.propTypes = {
    user: PropTypes.any,
    selectedUser: PropTypes.any,
    choicesUser: PropTypes.array,
    choicesUserLoaded: PropTypes.bool,
    hasBreadcrumb: PropTypes.bool,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    user: state.authReducer.user,
    selectedUser: state.userReducer.selectedUser,
    choicesUser: state.userReducer.choicesUser,
    choicesUserLoaded: state.userReducer.choicesUserLoaded,
    hasBreadcrumb: state.userReducer.breadcrumb,
}))(Header);
