import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import displayStyle from '../assets/jss/displayStyle';
import paddingStyle from '../assets/jss/paddingStyle';
import {Button, Modal, Typography, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    ...displayStyle(theme),
    ...paddingStyle(theme),
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        width: '80%',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        padding: theme.spacing(2),
    },
}))

const UnknownErrorModal = ({
    open = false,
}) => {
    const classes = useStyles();

    return (
        <div>
            <Modal
                open={open}
                className={classes.modal}
            >
                <div className={classes.modalContent}>
                    <Typography variant="h5">Une erreur est survenue</Typography>
                    <div className={clsx(classes.dFlexCenter, classes.mt3)}>
                        <Button type="button" variant="contained" color="primary"
                            onClick={() => window.location.reload()}>
                            Réessayer
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

UnknownErrorModal.propTypes = {
    open: PropTypes.bool,
}

export default UnknownErrorModal;