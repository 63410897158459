import Box from '@material-ui/core/Box';
import InputReservePrice from './InputReservePrice';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Grid, IconButton, LinearProgress, Typography} from '@material-ui/core';
import {downloadExcel} from '../api/api';
import {faCar, faFileExcel} from '@fortawesome/pro-duotone-svg-icons';
import {isEmpty} from 'ramda';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {setBreadcrumbs} from '../redux/user';
import {setVehiclesNavListWithBlocked} from '../redux/vehicles';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    tableHead: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.white,
        fontWeight: 'bold',
    },
    tableRow: {
        cursor: 'pointer',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1rem 0 1rem 0',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    active: {
        color: '#FFF !important',
    },
    root: {
        '&:hover': {
            color: '#fff',
        },
    },
    icon: {
        color: '#fff !important',
    },
    container: {
        overflow: 'hidden',
    },
}));

const CUSTOM_COLUMNS = ['reservePrice', 'linkFolder']

const ButtonVehicleFolder = ({
    vehicleId,
    row,
}) => {
    const dispatch = useDispatch();
    const breadcrumbs = useSelector(state => state.userReducer.breadcrumbs);

    let theme = useTheme(),
        history = useHistory(),
        location = useLocation(),
        title = '';

    if (location.pathname.includes('stock')) title = 'Véhicules en stock';
    if (location.pathname.includes('incoming')) title = 'Véhicules en vente';
    if (location.pathname.includes('past')) title = 'Véhicules en vente passée';
    if (location.pathname.includes('blocked')) title = 'Véhicules bloqués';

    const handleOnClick = () => {
        if (location.pathname.includes('blocked')) {
            dispatch(setVehiclesNavListWithBlocked(row.subStatus));
        }
        let oldBreadcrumbs = breadcrumbs !== null ? breadcrumbs : [];
        dispatch(setBreadcrumbs(oldBreadcrumbs.concat([{path: location.pathname, label: title}])))
        history.push('/vehicle/folder/' + vehicleId);
    }

    return (
        <IconButton onClick={handleOnClick}>
            <FontAwesomeIcon icon={faCar} color={theme.palette.success.main} size="sm"/>
        </IconButton>
    );
}

ButtonVehicleFolder.propTypes = {
    vehicleId: PropTypes.any,
    row: PropTypes.any,
}

const CustomColumnRender = ({
    columnId,
    row,
}) => {
    let location = useLocation();
    switch (columnId) {
    case 'reservePrice':
        if (location.pathname.includes('/vehicles/sales/incoming/')) {
            return <InputReservePrice
                defaultValue={row[columnId]}
                vehicle={row}
            />
        } else {
            return <>{row[columnId]}</>;
        }
    case 'linkFolder':
        return <ButtonVehicleFolder vehicleId={row.vehicleId} row={row}/>
    default:
        throw 'Put your custom component here.'
    }
}

CustomColumnRender.propTypes = {
    columnId: PropTypes.any,
    row: PropTypes.any,
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => {
        if (el instanceof Date) {
            return [el.getTime(), index]
        }

        return [el, index]
    });
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

const DataTable = (
    {
        columns = [],
        data = [],
        title = '',
        searchableColumns = [],
        searchInputPlaceHolder = 'Recherche',
        exportCsv = false,
        handleClick,
        defaultOrderBy = '',
        defaultOrderDesc = false,
        loading,
    }
) => {
    const classes = useStyles();
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [search, setSearch] = useState(null);
    // ORDER BY
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [order, setOrder] = useState(!defaultOrderDesc ? 'asc' : 'desc');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const stringCompare = (haystack, needle) => haystack.toLowerCase().includes(needle.toLowerCase())
    const dataFilter = obj => {
        if (!search) {
            return true;
        }

        for (const col of searchableColumns) {
            let value = (obj[col] instanceof Date) ? obj[col].toLocaleDateString() : obj[col];

            if (!obj[col]) {
                return;
            }

            if (stringCompare(value, search)) {
                return true;
            }
        }

        return false;
    }

    const filteredData = data.filter(dataFilter);
    // TODO : Do something which permit to DON'T USE data.excelExport which is so annoying to use
    //  because is basically the same list of fields provided by the columns props.
    const getExcel = () => downloadExcel(title, filteredData.map(data => data.excelExport));
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickLine = (row) => {
        if (handleClick) {
            handleClick(row);
        }
    }

    return (
        <>
            <Grid container alignItems="flex-end" justifyContent="space-between" style={{marginBottom: '1rem'}}>
                <Grid item xs={6}>
                    <Typography variant="h1">{title}</Typography>
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="flex-end" item xs={6}>
                    <Grid item>
                        <TextField
                            id="search"
                            name="search"
                            label={searchInputPlaceHolder}
                            variant="standard"
                            onChange={e => setSearch(e.target.value)}
                            disabled={isEmpty(data)}
                        />
                    </Grid>
                    {exportCsv
                        ? <Grid item>
                            <Tooltip title="Export XLS" style={{marginLeft: theme.spacing(3)}}>
                                <span>
                                    <IconButton
                                        id="excelButton"
                                        size="small"
                                        style={{borderRadius: '10%', fontSize: '2rem'}}
                                        onClick={() => {
                                            getExcel();
                                        }}
                                        disabled={isEmpty(data)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileExcel}
                                            color={isEmpty(data) ? theme.palette.grey.main : theme.palette.success.main}
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        : null
                    }
                </Grid>
            </Grid>
            <Paper>
                <TableContainer className={classes.container} style={{borderRadius: '5px'}}>
                    <Table stickyHeader size="small">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        className={classes.tableHead}
                                        style={{padding: '0 0 0 .1rem'}}
                                    >
                                        {column.sortable === false
                                            ? column.label
                                            : <TableSortLabel
                                                classes={{
                                                    active: classes.active,
                                                    root: classes.root,
                                                    icon: classes.icon,
                                                    iconDirectionAsc: classes.icon,
                                                    iconDirectionDesc: classes.icon,
                                                }}
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={(e) => handleRequestSort(e, column.id)}
                                            >
                                                {column.label}
                                                {orderBy === column.id
                                                    ? <span className={classes.visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                    : null
                                                }
                                            </TableSortLabel>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading
                                ? <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <span>Chargement</span>
                                        <LinearProgress/>
                                    </TableCell>
                                </TableRow>
                                : filteredData.length === 0
                                    ? <TableRow>
                                        <TableCell
                                            colSpan={columns.length}
                                            align='center'
                                        >
                                            <Box fontStyle="italic">
                                                Aucune donnée trouvée
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    : stableSort(filteredData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover={!!handleClick}
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className={handleClick ? classes.tableRow : ''}
                                                    onClick={() => handleClickLine(row)}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                style={{padding: '.2rem'}}
                                                            >
                                                                {CUSTOM_COLUMNS.includes(column.id)
                                                                    ? <CustomColumnRender columnId={column.id} row={row}/>
                                                                    : Object.prototype.hasOwnProperty.call(column, 'formatWithObj')
                                                                        ? column.formatWithObj(row)
                                                                        : column.format(value)
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Lignes par page :"
                />
            </Paper>
        </>
    );
}

DataTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    title: PropTypes.string,
    searchableColumns: PropTypes.array,
    searchInputPlaceHolder: PropTypes.string,
    exportCsv: PropTypes.bool,
    handleClick: PropTypes.func,
    defaultOrderBy: PropTypes.string,
    defaultOrderDesc: PropTypes.bool,
    loading: PropTypes.bool,
}

export default DataTable;