import DataTable from '../DataTable';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {fetchSalesData} from '../../api/apiSales';
import {removeVehicles} from '../../redux/vehicles';
import {salesIncomingModel} from '../../redux/sales';
import {setBreadcrumbs, setPrevLink} from '../../redux/user';
import {useHistory} from 'react-router-dom';

const DataTableSaleIncomingColumn = [
    {
        id: 'saleDateMoment',
        label: 'Date de vente',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'saleType',
        label: 'Salle de vente',
        formatWithObj: value => {
            let out = value['salesCity'];

            if (value['saleType'] === 'Electronique') {
                out = value['salesRoom'];
            }

            return out;
        },
    },
    {
        id: 'vehicleAmount',
        label: 'Nombre de véhicules',
        format: value => value,
    },
    {
        id: 'vehicleWithoutReserve',
        label: 'Véhicules sans réserve',
        format: value => value,
    },
]

const SalesIncoming = ({
    salesLoaded,
    salesIsLoading,
    currentSaleId,
    selectedUser,
    choicesUser,
    dispatch,
}) => {
    const sales = useSelector(salesIncomingModel);
    const history = useHistory();
    let title = 'Ventes à venir';

    useEffect(() => {
        if (((choicesUser.length !== 0 && selectedUser) || (choicesUser.length === 0 && !selectedUser)) && !salesLoaded) {
            fetchSalesData(selectedUser);
            dispatch(removeVehicles());
            dispatch(setBreadcrumbs(null));
        }

        // TODO : Put a button which allow to reload the list.
    }, [salesLoaded, selectedUser]);

    const handleClickTableRow = (row) => {
        dispatch(setPrevLink('/list/sales/incoming'));
        dispatch(setBreadcrumbs([{path: '/list/sales/incoming', label: title}]));

        // currentSaleId is set in SaleIncomingVehicle.js
        if (row['saleId'] !== currentSaleId) {
            dispatch(removeVehicles());
        }

        history.push('/list/vehicles/sales/incoming/' + row['saleId']);
    }

    return (
        <DataTable
            columns={DataTableSaleIncomingColumn}
            data={sales}
            exportCsv={true}
            handleClick={handleClickTableRow}
            searchableColumns={['salesRoom', 'saleDate']}
            loading={salesIsLoading}
        />
    );
}

SalesIncoming.propTypes = {
    salesLoaded: PropTypes.bool,
    salesIsLoading: PropTypes.bool,
    currentSaleId: PropTypes.number,
    selectedUser: PropTypes.any,
    choicesUser: PropTypes.any,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    salesLoaded: state.salesReducer.salesLoaded,
    salesIsLoading: state.salesReducer.salesIsLoading,
    currentSaleId: state.salesReducer.currentSaleId,
    selectedUser: state.userReducer.selectedUser,
    choicesUser: state.userReducer.choicesUser,
}))(SalesIncoming);