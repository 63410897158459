import PropTypes from 'prop-types';
import React from 'react';
import {Tabs} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// We can inject some CSS into the DOM.
const styles = {
    root: {
        background: '#f5f5f5',
    },
    indicator: {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        height: '10px',
        '& > span': {
            backgroundColor: 'transparent !important',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderBottom: '7px solid rgb(255, 108, 57)',
        },
    },
};

function TabsWarning(props) {
    const { classes, children, ...other } = props;

    return (
        <Tabs
            TabIndicatorProps={{ children: <span /> }}
            classes={{
                root: classes.root,
                indicator: classes.indicator,
            }} {...other}>
            {children}
        </Tabs>
    );
}

TabsWarning.propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(TabsWarning);