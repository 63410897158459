import PropTypes from 'prop-types';
import React from 'react';
import {IconButton} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

const VehicleActionButton = ({
    loaded = false,
    children,
}) => {

    if (!loaded) {
        return (
            <Skeleton variant="circle" style={{margin: '0.3rem'}}>
                <IconButton style={{margin: '0.7rem'}}/>
            </Skeleton>
        );
    }

    return (
        <>
            {children}
        </>
    );
}

VehicleActionButton.propTypes = {
    loaded: PropTypes.bool,
    children: PropTypes.any,
}

export default VehicleActionButton;