import {createSlice} from '@reduxjs/toolkit';

export const vehiclePhotoSlice = createSlice({
    name: 'vehiclePhoto',
    initialState: {
        vehiclePhotos: {},
        vehiclePhotosProduct: [],
        vehiclePhotosMisc: [],
        vehiclePhotosdamages: [],
        vehiclePhotoPanorama: null,
        vehiclePhotosLoaded: false,
        vehiclePhotosIsLoading: false,
    },
    reducers: {
        setVehiclePhotos: (state, action) => {
            let photosProduct =
                Object.prototype.hasOwnProperty.call(action.payload, 'photos')
                && Object.prototype.hasOwnProperty.call(action.payload.photos, 'product')
                    ? Object.values(action.payload.photos['product'])
                    : []
            ;

            let photosMisc =
                Object.prototype.hasOwnProperty.call(action.payload, 'photos')
                && Object.prototype.hasOwnProperty.call(action.payload.photos, 'misc')
                    ? Object.values(action.payload.photos['misc'])
                    : []
            ;

            let photosDamages =
                Object.prototype.hasOwnProperty.call(action.payload, 'photos')
                && Object.prototype.hasOwnProperty.call(action.payload.photos, 'damages')
                    ? Object.values(action.payload.photos['damages'])
                    : []
            ;

            let panorama =
                Object.prototype.hasOwnProperty.call(action.payload, 'iframes')
                && Object.prototype.hasOwnProperty.call(action.payload, 'panorama')
                    ? action.payload.iframes['panorama']
                    : null
            ;

            let photos = photosProduct.concat(photosMisc).concat(photosDamages);

            photos = {...photos, iframe: null};

            // The iframe_encryt is either in the iframes.product or in the iframes.misc.
            Object.prototype.hasOwnProperty.call(action.payload, 'iframes')
            && Object.prototype.hasOwnProperty.call(action.payload.iframes, 'product')
                ? Object.prototype.hasOwnProperty.call(action.payload.iframes.product, 'iframe_encrypt')
                    ? photos.iframe = action.payload.iframes.product.iframe_encrypt
                    : null
                : null
            ;

            Object.prototype.hasOwnProperty.call(action.payload, 'iframes')
            && Object.prototype.hasOwnProperty.call(action.payload.iframes, 'misc')
                ? Object.prototype.hasOwnProperty.call(action.payload.iframes.misc, 'iframe_encrypt')
                    ? photos.iframe = action.payload.iframes.misc.iframe_encrypt
                    : null
                : null
            ;

            return {
                ...state,
                vehiclePhotos: photos,
                vehiclePhotosProduct: photosProduct,
                vehiclePhotosMisc: photosMisc,
                vehiclePhotosDamages: photosDamages,
                vehiclePhotoPanorama: panorama,
                vehiclePhotosLoaded: true,
                vehiclePhotosIsLoading: false,
            }
        },
        removeVehiclePhotos: (state) => {
            return {
                ...state,
                vehiclePhotos: null,
                vehiclePhotosLoaded: false,
                vehiclePhotosIsLoading: false,
            }
        },
        setVehiclePhotosIsLoading: (state, action) => {
            return {
                ...state,
                vehiclePhotosIsLoading: action.payload,
            }
        },
    },
});

export const {actions, reducer} = vehiclePhotoSlice;

export const {
    setVehiclePhotos,
    setVehiclePhotosIsLoading,
    removeVehiclePhotos,
} = actions;

