import * as R from 'ramda';
import {createSelector, createSlice} from '@reduxjs/toolkit';

export const vehicleComplementarySlice = createSlice({
    name: 'vehicleComplementary',
    initialState: {
        vehicleComplementary: null,
        vehicleComplementaryLoaded: false,
        vehicleComplementaryIsLoading: false,
    },
    reducers: {
        setVehicleComplementary: (state, action) => {
            return {
                ...state,
                vehicleComplementary: action.payload,
                vehicleComplementaryLoaded: true,
                vehicleComplementaryIsLoading: false,
            }
        },
        removeVehicleComplementary: (state) => {
            return {
                ...state,
                vehicleComplementary: null,
                vehicleComplementaryLoaded: false,
                vehicleComplementaryIsLoading: false,
            }
        },
        setVehicleComplementaryIsLoading: (state, action) => {
            return {
                ...state,
                vehicleComplementaryIsLoading: action.payload,
            }
        },
    },
});

export const equipmentsSortByWeight = createSelector(
    state => state.vehicleComplementaryReducer.vehicleComplementary,
    vehicleComplementary => vehicleComplementary
        ? R.clone(vehicleComplementary['equipments'])
            .sort((firstEquipment, secondEquipment) => Number(secondEquipment['weight'] - firstEquipment['weight']))
        : []
)

export const {actions, reducer} = vehicleComplementarySlice;

export const {
    setVehicleComplementary,
    setVehicleComplementaryIsLoading,
    removeVehicleComplementary,
} = actions;

