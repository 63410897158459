import Sale from '../model/Sale';
import {createSelector, createSlice} from '@reduxjs/toolkit';

export const salesSlice = createSlice({
    name: 'sales',
    initialState: {
        salesLoaded: false,
        salesIsLoading: false,
        sales: [],
        currentSaleId: 0,
        outdatedSales: [],
        outdatedSalesLoaded: false,
        outdatedSalesIsLoading: false,
        currentOutdatedSaleId: 0,
    },
    reducers: {
        setSales: (state, action) => {
            return {
                ...state,
                sales: action.payload,
                salesLoaded: true,
                salesIsLoading: false,
            }
        },
        setSalesIsLoading: (state, action) => {
            return {
                ...state,
                salesIsLoading: action.payload,
            }
        },
        removeSales: (state) => {
            return {
                ...state,
                sales: [],
                salesLoaded: false,
                salesIsLoading: false,
            }
        },
        setCurrentSaleId: (state, action) => {
            return {
                ...state,
                currentSaleId: action.payload,
            }
        },
        setOutdatedSales: (state, action) => {
            return {
                ...state,
                outdatedSales: action.payload,
                outdatedSalesLoaded: true,
                outdatedSalesIsLoading: false,
            }
        },
        setOutdatedSalesIsLoading: (state, action) => {
            return {
                ...state,
                outdatedSalesIsLoading: action.payload,
            }
        },
        removeOutdatedSales: (state) => {
            return {
                ...state,
                outdatedSales: [],
                outdatedSalesLoaded: false,
                outdatedSalesIsLoading: false,
            }
        },
        setCurrentOutdatedSaleId: (state, action) => {
            return {
                ...state,
                currentOutdatedSaleId: action.payload,
            }
        },
    },
});

export const salesIncomingModel = createSelector(
    state => state.salesReducer.sales,
    sales => sales ? sales.map(sale => new Sale(sale, 'salesIncoming')) : []
);

export const salesOutdatedModel = createSelector(
    state => state.salesReducer.outdatedSales,
    sales => sales.map(sale => new Sale(sale))
);

export const {actions, reducer} = salesSlice;

export const {
    setSales,
    setSalesIsLoading,
    removeSales,
    setCurrentSaleId,
    setOutdatedSales,
    setOutdatedSalesIsLoading,
    removeOutdatedSales,
    setCurrentOutdatedSaleId,
} = actions;



