import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {editRowWithReservePrice} from '../redux/vehicles';
import {faCheckCircle} from '@fortawesome/pro-duotone-svg-icons';
import {makeStyles, useTheme} from '@material-ui/core';
import {updateReservePrice} from '../api/apiVehicles';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
    },
    input: {
        width: '80px',
    },
    icon: {
        width: '20px',
        height: '20px',
        position: 'absolute',
        right: '4%',
        top: '23%',
    },
}));

const InputReservePrice = ({
    defaultValue,
    vehicle,
}) => {
    const [value, setValue] = React.useState(defaultValue);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [isChanged, setIsChanged] = React.useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();

    // Set value when data in dataTable change with a search or an order, and change defaultValue.
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue])


    const handleChange = (e) => {
        // Accept only number.
        if (!e.target.value.match(/^\d+$/) && e.target.value !== '') {
            return;
        }
        setSuccess(false);
        setIsChanged(true);
        setValue(e.target.value);
        // TextField of type number doesn't accept empty string.
        let value = e.target.value === '' ? null : e.target.value;
        dispatch(editRowWithReservePrice({value: value, id: vehicle.vehicleId}));
    }

    const sendReservePrice = (e) => {
        if (!isChanged){
            return;
        }
        setLoading(true);

        const orderNumber = vehicle.complementOrderNumber
            ? vehicle.order + ' ' + vehicle.complementOrderNumber
            : vehicle.order
        ;

        updateReservePrice(vehicle.vehicleId, e.target.value, orderNumber)
            .then(() => {
                setLoading(false);
                setSuccess(true);
                setIsChanged(false);
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
            })
            .catch(() => {
                setLoading(false);
            })
        ;
    }

    return (
        <div className={classes.root}>
            <TextField
                id={vehicle.vehicleId.toString()}
                size="small"
                variant="outlined"
                value={value}
                className={classes.input}
                onChange={handleChange}
                onBlur={sendReservePrice}
                disabled={loading}
            />
            {loading
                ? <CircularProgress size="small" className={classes.icon}/>
                : null
            }
            {success
                ? <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.success.main} className={classes.icon}/>
                : null
            }
        </div>
    )
};

InputReservePrice.propTypes = {
    defaultValue: PropTypes.any,
    vehicle: PropTypes.object,
}

export default InputReservePrice;