const displayStyle = () => ({
    dFlex: {
        display: 'flex',
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    dFlexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    alignItemsCenter: {
        display: 'flex',
        alignItems: 'center',
    },
});

export default displayStyle;