import PropTypes from 'prop-types';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';

const PrivateRoute = ({
    component: Component,
    user = null,
    ...rest
}) => {
    if (!user){
        console.log('REDIRECT TO LOGIN FROM PRIVATE ROUTE')
        return <Redirect to={{pathname: '/login'}}/>;
    }

    return (<Route {...rest} component={Component} exact/>);
};

PrivateRoute.propTypes = {
    component: PropTypes.object,
    user: PropTypes.any,
    tokenLoaded: PropTypes.bool,
}

export default connect(state => ({
    user: state.authReducer.user,
}))(PrivateRoute)
