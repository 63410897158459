import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Backdrop, CircularProgress, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {fetchUserEmailToken} from '../api/api';
import {useHistory, useParams} from 'react-router-dom';

const Sso = ({
    tokenLoaded = false,
    user = null,
    dispatch,
}) => {
    const param = useParams()
    const history = useHistory();
    const [status, setStatus] = useState('WAITING')

    useEffect(() => {
        if(!Object.prototype.hasOwnProperty.call(param,'email') || !Object.prototype.hasOwnProperty.call(param,'token')){
            setStatus('ERREUR')
        } else {
            if(tokenLoaded) {
                if (user === null) {
                    fetchUserEmailToken(param.email, param.token.replace('____','/'), dispatch)
                        .then(() => {
                            console.log('TENTATIVE REDIRECT APRES THEN')
                            history.push('/list/sales/incoming')
                        })
                        .catch((error) => {
                            console.log(error)
                            setStatus('ERREUR')
                        })
                } else {
                    console.log('TENTATIVE REDIRECT')
                    history.push('/list/sales/incoming')
                }
            }
        }
    }, [user,tokenLoaded]);

    useEffect(() => {
        if(status === 'ERREUR'){
            history.push('/login')
        }
    }, [status])

    return (
        <Backdrop open={true} style={{backgroundColor: 'white'}}>
            <Grid container style={{maxWidth: '30%'}}>
                <Grid item xs={12}>
                    <img src="/logo.png" style={{maxWidth: '100%'}} alt="Encheres VO"/>
                </Grid>
                <Grid item xs={3} style={{display: 'flex',justifyContent: 'right'}}>
                    <CircularProgress style={{marginRight: '10px',color: '#004A9C'}} />
                </Grid>
                <Grid item xs={9} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20}}>
                    <span>Authentification en cours ...</span>
                </Grid>
            </Grid>
        </Backdrop>
    )
}

Sso.propTypes = {
    route: PropTypes.string,
    tokenLoaded: PropTypes.bool,
    user: PropTypes.any,
    dispatch: PropTypes.func,
}

export default connect()(Sso)
