import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton, makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {faArrowCircleLeft, faArrowCircleRight} from '@fortawesome/pro-duotone-svg-icons';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0),
    },
}))

const VehicleSwiper = ({
    vehicles = [],
    currentVehicle = null,
    vehicleLoaded = false,
}) => {
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);
    const classes = useStyles();
    let history = useHistory();

    useEffect(() => {
        if (vehicleLoaded && Array.isArray(vehicles)) {
            let currentIndex = vehicles.findIndex(vehicle => vehicle['docEntryVH'] === currentVehicle['docEntryVH']);
            setPrev(vehicles[(currentIndex - 1)] !== undefined ? vehicles[(currentIndex - 1)] : false);
            setNext(vehicles[(currentIndex + 1)] !== undefined ? vehicles[(currentIndex + 1)] : false);
        }
    }, [vehicleLoaded])

    return (
        vehicles.length
            ? <div className={classes.root}>
                <Tooltip title="Précédent" disableFocusListener={!next || !vehicleLoaded}>
                    {/* The following <span> prevent a Material-UI console error. */}
                    <span>
                        <IconButton disabled={!prev || !vehicleLoaded} onClick={() => {
                            if (prev) {
                                history.push('/vehicle/folder/' + prev['docEntryVH'])
                            }
                        }}>
                            <FontAwesomeIcon icon={faArrowCircleLeft}/>
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Suivant">
                    {/* The following <span> prevent a Material-UI console error. */}
                    <span>
                        <IconButton disabled={!next || !vehicleLoaded} onClick={() => {
                            if (next) {
                                history.push('/vehicle/folder/' + next['docEntryVH'])
                            }
                        }}>
                            <FontAwesomeIcon icon={faArrowCircleRight}/>
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
            : null
    )
}

VehicleSwiper.propTypes = {
    vehicles: PropTypes.array,
    currentVehicle: PropTypes.any,
    vehicleLoaded: PropTypes.bool,
}

export default connect(state => ({
    vehicles: state.vehiclesReducer.vehiclesNavList,
    currentVehicle: state.vehicleReducer.vehicleFolder,
    vehicleLoaded: state.vehicleReducer.vehicleFolderLoaded,
}))(VehicleSwiper);