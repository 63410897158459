import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton} from '@material-ui/core';
import {faFileAlt} from '@fortawesome/pro-duotone-svg-icons/faFileAlt';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    dropdown: {
        position: 'absolute',
        right: 0,
        zIndex: 1,
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));

const DropdownMemo = ({
    children,
}) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <Tooltip placement="top" title="Memo">
                    <IconButton type="button" onClick={handleClick}>
                        <FontAwesomeIcon icon={faFileAlt}/>
                    </IconButton>
                </Tooltip>
                {open ? (
                    <div className={classes.dropdown}>
                        {children}
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

DropdownMemo.propTypes = {
    children: PropTypes.any,
}

export default DropdownMemo;