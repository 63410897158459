import DataTable from '../DataTable';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import ServicesEmailMenu from '../ServicesEmailMenu';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect, useSelector} from 'react-redux';
import {faStickyNote} from '@fortawesome/pro-duotone-svg-icons';
import {
    fetchVehiclesBlocked,
} from '../../api/apiVehicles';
import {isEmpty} from 'ramda';
import {red} from '@material-ui/core/colors';
import {setBreadcrumbs} from '../../redux/user';
import {subStatusToTitle} from '../../core/utils';
import {vehiclesBlockedModel} from '../../redux/vehicles';

const DataTableColumns = [
    {
        id: 'referenceSeller',
        label: 'Réf.',
        format: value => value,
    },
    {
        id: 'nj',
        label: 'Nb. jour',
        formatWithObj: obj => obj.getDaysFromCreation(),
    },
    {
        id: 'plateNumber',
        label: 'Immat.',
        format: value => value,
    },
    {
        id: 'type',
        label: 'Genre',
        format: value => value,
    },
    {
        id: 'brand',
        label: 'Marque',
        format: value => value,
    },
    {
        id: 'commercialType',
        label: 'Type comm.',
        format: value => value,
    },
    {
        id: 'ownerDebtor',
        label: 'Propriét./Déb.',
        format: value => value,
    },
    {
        id: 'createdAtMoment',
        label: 'Création',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'deliveredAtMoment',
        label: 'Livraison',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'saleAtMoment',
        label: 'Vente',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'storageParkCity',
        label: 'Parc stockage',
        format: value => value,
    },
    {
        id: 'status',
        label: 'Statut',
        format: value => value,
    },
    {
        id: 'subStatus',
        label: 'Sous statut',
        format: value => value,
    },
    {
        id: 'dateReceiptGreyCard',
        label: 'Réception CG',
        format: value => value,
    },
    {
        id: 'statutScanSE',
        label: 'SE',
        format: value => value,
    },
    {
        id: 'hasFE',
        label: 'FE',
        format: value => value,
    },
    {
        id: 'linkFolder',
    },
]

const VehiclesBlocked = ({
    vehiclesBlockedLoaded,
    vehiclesIsLoading,
    selectedUser,
    choicesUser,
    dispatch,
}) => {
    const vehiclesBlocked = useSelector(vehiclesBlockedModel);

    useEffect(() => {
        if (((choicesUser.length !== 0 && selectedUser) || (choicesUser.length === 0 && !selectedUser)) && !vehiclesBlockedLoaded) {
            fetchVehiclesBlocked(selectedUser);
            dispatch(setBreadcrumbs(null));
        }

        // TODO : Put a button which allow to reload the list.
    }, [vehiclesBlockedLoaded, selectedUser]);

    return (
        <>
            {!vehiclesBlockedLoaded || isEmpty(vehiclesBlocked)
                ? <DataTable
                    columns={DataTableColumns}
                    data={[]}
                    loading={vehiclesIsLoading}
                    searchInputPlaceHolder="Recherche par immat."
                />
                : Object.keys(vehiclesBlocked).map(key => 
                    <DataTable
                        key={key}
                        columns={DataTableColumns}
                        data={vehiclesBlocked[key]}
                        title={subStatusToTitle(key)}
                        searchableColumns={['plateNumber']}
                        searchInputPlaceHolder="Recherche par immat."
                    />
                )
            }
        </>
    );
}

VehiclesBlocked.propTypes = {
    vehiclesBlockedLoaded: PropTypes.bool,
    vehiclesIsLoading: PropTypes.bool,
    selectedUser: PropTypes.any,
    choicesUser: PropTypes.any,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    vehiclesBlockedLoaded: state.vehiclesReducer.vehiclesBlockedLoaded,
    vehiclesIsLoading: state.vehiclesReducer.vehiclesBlockedIsLoading,
    selectedUser: state.userReducer.selectedUser,
    choicesUser: state.userReducer.choicesUser,
}))(VehiclesBlocked);