import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect, useSelector} from 'react-redux';
import {faPaperPlane} from '@fortawesome/pro-duotone-svg-icons';
import {servicesEmailsByServiceName} from '../redux/util';

const ServicesEmailMenu = ({
    size = 'medium',
    cardNameSeller = '',
    vehicleDocNum = '',
    vehiclePlateNumber = '',
    status = '',
    subStatus = '',
    servicesEmailsIsLoading,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const servicesEmails = useSelector(servicesEmailsByServiceName);
    const subject = 'Demande depuis l’extranet EncheresVo : ' + cardNameSeller + ' pour le véhicule ' + vehicleDocNum + ' / Immat ' + vehiclePlateNumber;
    const body = 'Vendeur ' + cardNameSeller + ', Dossier ' + vehicleDocNum + ' / Immatriculation ' + vehiclePlateNumber + ', Statut ' + status + ' ' + subStatus;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickService = (emails) => {
        setAnchorEl(null);
        window.location.href = 'mailto:' + emails.join(',') + '?body=' + body + '&subject=' + subject;
    }

    return (
        <div>
            <Tooltip placement="top" title="Envoyer un email">
                <span>
                    <IconButton
                        size={size}
                        type="button"
                        onClick={handleClick}
                        disabled={servicesEmailsIsLoading}
                    >
                        <FontAwesomeIcon icon={faPaperPlane}/>
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {servicesEmails.map((serviceEmail, index) => (
                    <MenuItem key={index} onClick={() => handleClickService(serviceEmail['emails'])}>
                        {serviceEmail['serviceName']}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

ServicesEmailMenu.propTypes = {
    size: PropTypes.string,
    cardNameSeller: PropTypes.string,
    vehicleDocNum: PropTypes.string,
    vehiclePlateNumber: PropTypes.string,
    status: PropTypes.string,
    subStatus: PropTypes.string,
    servicesEmailsIsLoading: PropTypes.bool,
}

export default connect(state => ({
    servicesEmailsIsLoading: state.utilReducer.servicesEmailsIsLoading,
}))(ServicesEmailMenu);