import {apiPost} from './api';
import {getStore} from '../index';
import {
    removeSales,
    setOutdatedSales,
    setOutdatedSalesIsLoading,
    setSales,
    setSalesIsLoading,
} from '../redux/sales';

export const fetchSalesData = selectedUser => {
    let data = selectedUser ? {cardCode: selectedUser['cardCode'],cardCodeFamily: selectedUser['cardCodeFamily']} : null;

    getStore().dispatch(setSalesIsLoading(true));
    apiPost('/api/sales/incoming', data)
        .then(response => {
            getStore().dispatch(setSales(response));
        })
        .catch(() => {
            getStore().dispatch(removeSales());
        })
    ;
}

// pages/SalesOutdated.js
export const fetchOutdatedSales = selectedUser => {
    let data = selectedUser ? {cardCode: selectedUser['cardCode'],cardCodeFamily: selectedUser['cardCodeFamily']}  : null;

    getStore().dispatch(setOutdatedSalesIsLoading(true));
    apiPost('/api/sales/outdated', data)
        .then(response => {
            getStore().dispatch(setOutdatedSales(response));
        })
        .catch(() => {
            getStore().dispatch(removeSales());
        })
    ;
}