import orange from '@material-ui/core/colors/orange';
import {createTheme} from '@material-ui/core';
import {dark} from '@material-ui/core/styles/createPalette';
import {red} from '@material-ui/core/colors';

const theme = createTheme({
    overrides: {
        MUIDataTable: {
            root: {
                backgroundColor: '#AAF',
            },
            paper: {
                boxShadow: 'none',
            },
        },
        MUIDataTableBodyCell: {
            root: {
                backgroundColor: '#FFF',
            },
        },
        MuiTableCell: {
            root: {
                fontSize: '0.7rem',
                fontWeight: 600,
            },
            head: {
                backgroundColor: '#000',
            },
            sizeSmall: {
                padding: '4px',
            },
        },
        MUIDataTableSelectCell: {
            headerCell: {
                backgroundColor: '#000',
            },
        },
        MuiSkeleton: {
            text: {
                transform: 'none',
            },
        },
    },
    typography: {
        h1: {
            fontSize: '1.5rem',
            color: '#0E1D37',
            fontWeight: 600,
        },
        body1: {
            fontSize: '13px',
        },
        fontFamily: [
            'Open sans',
            'Raleway',
            'Roboto',
        ].join(','),
    },
    palette: {
        background: {
            default: '#E3E3E3',
        },
        success: {
            main: '#28a745',
        },
        primary: {
            main: '#19325F',
            dark: '#0E1D37',
        },
        warning: {
            main: orange[500],
        },
        danger: {
            main: red[500],
        },
        dark: {
            main: dark[500],
        },
        secondary: {
            main: '#6C757D',
        },
        white: '#FFF',
    },
});

export default theme;