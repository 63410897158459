import DataTable from '../DataTable';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import ServicesEmailMenu from '../ServicesEmailMenu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect, useSelector} from 'react-redux';
import {faStickyNote} from '@fortawesome/pro-duotone-svg-icons';
import {
    fetchVehiclesStock,
} from '../../api/apiVehicles';
import {isEmpty} from 'ramda';
import {red} from '@material-ui/core/colors';
import {setBreadcrumbs} from '../../redux/user';
import {
    vehiclesStockModel,
} from '../../redux/vehicles';

const DataTableColumns = [
    {
        id: 'referenceSeller',
        label: 'Réf.',
        format: value => value,
    },
    {
        id: 'nj',
        label: 'Nb. jour',
        formatWithObj: obj => obj.getDaysFromCreation(),
    },
    {
        id: 'plateNumber',
        label: 'Immat.',
        format: value => value,
    },
    {
        id: 'type',
        label: 'Genre',
        format: value => value,
    },
    {
        id: 'brand',
        label: 'Marque',
        format: value => value,
    },
    {
        id: 'commercialType',
        label: 'Type comm.',
        format: value => value,
    },
    {
        id: 'ownerDebtor',
        label: 'Propriét./Déb.',
        format: value => value,
    },
    {
        id: 'createdAtMoment',
        label: 'Création',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'deliveredAtMoment',
        label: 'Livraison',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'saleAtMoment',
        label: 'Vente',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'storageParkCity',
        label: 'Parc stockage',
        format: value => value,
    },
    {
        id: 'status',
        label: 'Statut',
        format: value => value,
    },
    {
        id: 'subStatus',
        label: 'Sous statut',
        format: value => value,
    },
    {
        id: 'dateReceiptGreyCard',
        label: 'Réception CG',
        format: value => value,
    },
    {
        id: 'statutScanSE',
        label: 'SE',
        format: value => value,
    },
    {
        id: 'hasFE',
        label: 'FE',
        format: value => value,
    },
    {
        id: 'linkFolder',
    },
]

const VehiclesStock = ({
    vehiclesLoaded,
    vehiclesIsLoading,
    selectedUser,
    choicesUser,
    dispatch,
}) => {
    const vehicles = useSelector(vehiclesStockModel);

    useEffect(() => {
        if (((choicesUser.length !== 0 && selectedUser) || (choicesUser.length === 0 && !selectedUser)) && !vehiclesLoaded) {
            fetchVehiclesStock(selectedUser);
            dispatch(setBreadcrumbs(null));
        }

        // TODO : Put a button which allow to reload the list.
    }, [vehiclesLoaded, selectedUser]);

    return (
        <DataTable
            columns={DataTableColumns}
            data={vehicles}
            exportCsv={true}
            title={isEmpty(vehicles) ? 'Stock' : 'Stock ('+vehicles.length+')'}
            searchableColumns={['plateNumber']}
            searchInputPlaceHolder="Recherche par immat."
            loading={vehiclesIsLoading}
        />
    );
}

VehiclesStock.propTypes = {
    vehiclesLoaded: PropTypes.bool,
    vehiclesIsLoading: PropTypes.bool,
    selectedUser: PropTypes.any,
    choicesUser: PropTypes.any,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    vehiclesLoaded: state.vehiclesReducer.vehiclesStockLoaded,
    vehiclesIsLoading: state.vehiclesReducer.vehiclesStockIsLoading,
    selectedUser: state.userReducer.selectedUser,
    choicesUser: state.userReducer.choicesUser,
}))(VehiclesStock);