import * as R from 'ramda';
import VehicleFolder from '../model/VehicleFolder';
import VehicleSale from '../model/VehicleSale';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import {transformDataToDisplayableValue} from '../core/dataTransformer';

export const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState: {
        vehiclesLoaded: false,
        vehiclesIsLoading: false,
        vehicles: [],
        vehiclesOutdated: [],
        vehiclesOutdatedLoaded: false,
        vehiclesOutdatedIsLoading: false,
        vehiclesBlocked: [],
        vehiclesBlockedLoaded: false,
        vehiclesBlockedIsLoading: false,
        vehiclesStock: [],
        vehiclesStockLoaded: false,
        vehiclesStockIsLoading: false,
        vehiclesNavList: [],
    },
    reducers: {
        setVehicles: (state, action) => {
            return {
                ...state,
                vehicles: action.payload,
                vehiclesLoaded: true,
                vehiclesIsLoading: false,
                vehiclesNavList: action.payload,
            }
        },
        setVehiclesIsLoading: (state, action) => {
            return {
                ...state,
                vehiclesIsLoading: action.payload,
            }
        },
        removeVehicles: (state) => {
            return {
                ...state,
                vehicles: [],
                vehiclesLoaded: false,
                vehiclesIsLoading: false,
            }
        },
        setVehiclesOutdated: (state, action) => {
            return {
                ...state,
                vehiclesOutdated: action.payload,
                vehiclesOutdatedLoaded: true,
                vehiclesOutdatedIsLoading: false,
                vehiclesNavList: action.payload,
            }
        },
        setVehiclesOutdatedIsLoading: (state, action) => {
            return {
                ...state,
                vehiclesOutdatedIsLoading: action.payload,
            }
        },
        removeVehiclesOutdated: (state) => {
            return {
                ...state,
                vehiclesOutdated: [],
                vehiclesOutdatedLoaded: false,
                vehiclesOutdatedIsLoading: false,
            }
        },
        setVehiclesBlocked: (state, action) => {
            return {
                ...state,
                vehiclesBlocked: action.payload,
                vehiclesBlockedLoaded: true,
                vehiclesBlockedIsLoading: false,
                vehiclesNavList: action.payload,
            }
        },
        setVehiclesBlockedIsLoading: (state, action) => {
            return {
                ...state,
                vehiclesBlockedIsLoading: action.payload,
            }
        },
        removeVehiclesBlocked: (state) => {
            return {
                ...state,
                vehiclesBlocked: [],
                vehiclesBlockedLoaded: false,
                vehiclesBlockedIsLoading: false,
            }
        },
        setVehiclesStock: (state, action) => {
            return {
                ...state,
                vehiclesStock: action.payload,
                vehiclesStockLoaded: true,
                vehiclesStockIsLoading: false,
                vehiclesNavList: action.payload,
            }
        },
        setVehiclesStockIsLoading: (state, action) => {
            return {
                ...state,
                vehiclesStockIsLoading: action.payload,
            }
        },
        removeVehiclesStock: (state) => {
            return {
                ...state,
                vehiclesStock: [],
                vehiclesStockLoaded: false,
                vehiclesStockIsLoading: false,
            }
        },
        setVehiclesNavListWithBlocked: (state, action) => {
            let vehiclesBlocked = R.clone(state.vehiclesBlocked);
            return {
                ...state,
                vehiclesNavList: vehiclesBlocked[action.payload],
            }
        },
        editRowWithReservePrice: (state, action) => {
            let vehicles = [...state.vehicles],
                vehicle = vehicles.find(e => e['docEntryVH'] === action.payload.id);

            vehicle['prixReserve'] = action.payload.value;
            state.vehicles = vehicles;
        },
    },
});

export const {actions, reducer} = vehiclesSlice;

export const {
    setVehicles,
    setVehiclesIsLoading,
    removeVehicles,
    setVehiclesBlocked,
    setVehiclesBlockedIsLoading,
    removeVehiclesBlocked,
    setVehiclesOutdated,
    setVehiclesOutdatedIsLoading,
    removeVehiclesOutdated,
    setVehiclesStock,
    setVehiclesStockIsLoading,
    removeVehiclesStock,
    editRowWithReservePrice,
    setVehiclesNavListWithBlocked,
} = actions;

export const vehiclesSaleIncoming = createSelector(
    state => state.vehiclesReducer.vehicles,
    vehicles => vehicles.map(vehicle => new VehicleSale(vehicle, 'SaleIncomingVehicles'))
);

export const vehiclesBlockedModel = createSelector(
    state => state.vehiclesReducer.vehiclesBlocked,
    vehiclesByStatus => {
        let vehiclesByStatusObj = {};
        Object.keys(vehiclesByStatus).forEach(key => {
            vehiclesByStatusObj[key] = vehiclesByStatus[key].map(vehicle => new VehicleFolder(vehicle));
        });
        return vehiclesByStatusObj;
    }
);

export const vehiclesStockModel = createSelector(
    state => state.vehiclesReducer.vehiclesStock,
    vehicles => vehicles.map(vehicle => new VehicleFolder(
        transformDataToDisplayableValue(R.clone(vehicle)),
        'vehiclesStock'
    ))
);

export const vehiclesSalePast = createSelector(
    state => state.vehiclesReducer.vehiclesOutdated,
    vehicles => vehicles.map(vehicle => new VehicleSale(
        transformDataToDisplayableValue(R.clone(vehicle)),
        'SalePastVehicles'
    ))
);

export const vehiclesArray = createSelector(
    state => state.vehiclesReducer.vehicles,
    vehicles => vehicles.map(vehicle => new VehicleSale(vehicle)).map(vehicle => [
        vehicle.plateNumber, vehicle.type, vehicle.brand, vehicle.commercialType, vehicle.reservePrice,
    ])
);