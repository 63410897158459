import {clearUserData} from '../api/api';
import {createSlice} from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        tokenLoaded: false,
        tokenLoading: true,
        user: null,
    },
    reducers: {
        setUser: (state, action) => {
            if (!action.payload) {
                clearUserData();
            }
            return {
                ...state,
                user: action.payload,
            }
        },
        setTokenLoaded: (state, action) => {
            return {
                ...state,
                tokenLoaded: action.payload,
            }
        },
        setTokenLoading: (state, action) => {
            return {
                ...state,
                tokenLoading: action.payload,
            }
        },
    },
});

export const {actions, reducer} = authSlice;

export const {
    setUser,
    setTokenLoaded,
    setTokenLoading,
} = actions;
