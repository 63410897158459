import DataTable from '../DataTable';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {fetchOutdatedVehiclesData} from '../../api/apiVehicles';
import {setCurrentOutdatedSaleId} from '../../redux/sales';
import {useParams} from 'react-router-dom';
import {vehiclesSalePast} from '../../redux/vehicles';

const DataTableVehiclesOutdatedColumn = [
    {
        id: 'order',
        label: 'Ord.',
        formatWithObj: obj => obj.order ? obj.order + ' ' + obj.complementOrderNumber : '',
    },
    {
        id: 'referenceSeller',
        label: 'Réf.',
        format: value => value,
    },
    {
        id: 'plateNumber',
        label: 'Immat.',
        format: value => value,
    },
    {
        id: 'type',
        label: 'Ge.',
        format: value => value,
    },
    {
        id: 'brand',
        label: 'Marque',
        format: value => value,
    },
    {
        id: 'commercialType',
        label: 'Type comm.',
        format: value => value,
    },
    {
        id: 'color',
        label: 'Couleur',
        format: value => value,
    },
    {
        id: 'registeredAtMoment',
        label: 'M.E.C',
        format: value => value ? value.format('DD/MM/YYYY') : '',
    },
    {
        id: 'kilomReal',
        label: 'Km.',
        format: value => value,
    },
    {
        id: 'cotePerso',
        label: 'CP',
        format: value => value,
    },
    {
        id: 'coteKilo',
        label: 'CK',
        format: value => value,
    },
    {
        id: 'reservePrice',
        label: 'Rés.',
        format: value => value,
    },
    {
        id: 'estimationPrice',
        label: 'Est.',
        format: value => value,
    },
    {
        id: 'adjudicationPrice',
        label: 'Adjugé',
        format: value => value,
    },
    {
        id: 'revocationPrice',
        label: 'Retiré',
        format: value => value,
    },
    {
        id: 'personalQuotingAdjudicationRatio',
        label: '%Adj/CP',
        format: value =>  value ? value + '%' : null,
    },
    {
        id: 'mileageQuotingAdjudicationRatio',
        label: '%Adj/CK',
        format: value =>  value ? value + '%' : null,
    },
    {
        id: 'observation',
        label: 'Obs.',
        format: value => value,
    },
    {
        id: 'repairCost',
        label: 'FRE',
        format: value => value,
    },
    {
        id: 'doorsNumber',
        label: 'Nb. portes',
        format: value => value,
    },
    {
        id: 'tva',
        label: 'TVA',
        format: value => value,
    },
    {
        id: 'linkFolder',
    },
];

const SalePastVehicles = ({
    vehiclesLoaded,
    vehiclesIsLoading,
    selectedUser,
    dispatch,
}) => {
    const vehicles = useSelector(vehiclesSalePast);
    const {saleId} = useParams();
    const dateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    let title = vehicles.length
        ? 'Vente du ' + vehicles[0].saleDate.toLocaleString('fr-FR', dateOptions) + ' (' + vehicles.length + ')'
        : ''
    ;

    useEffect(() => {
        if (!vehiclesLoaded) {
            fetchOutdatedVehiclesData(saleId, selectedUser);
        }

        // Allow to not reload the vehicles of the sale if the user don't ask other vehicles of another sale.
        dispatch(setCurrentOutdatedSaleId(parseInt(saleId)));
    }, [saleId, vehiclesLoaded]);

    return (
        <DataTable
            data={vehicles}
            exportCsv={true}
            title={title}
            searchableColumns={['plateNumber']}
            searchInputPlaceHolder="Recherche par immat."
            columns={DataTableVehiclesOutdatedColumn}
            loading={vehiclesIsLoading}
        />
    );
}

SalePastVehicles.propTypes = {
    vehiclesLoaded: PropTypes.bool,
    vehiclesIsLoading: PropTypes.bool,
    selectedUser: PropTypes.any,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    vehiclesLoaded: state.vehiclesReducer.vehiclesOutdatedLoaded,
    vehiclesIsLoading: state.vehiclesReducer.vehiclesOutdatedIsLoading,
    selectedUser: state.userReducer.selectedUser,
}))(SalePastVehicles);