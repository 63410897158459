import moment from 'moment';
import {BaseModel} from 'sjs-base-model';
import {stringToMoment} from '../core/dataTransformer';

export default class Sale extends BaseModel {
    saleId = '';
    saleDate = '';
    salesRoom = '';
    salesCity = '';
    saleHour = '';
    saleType = '';
    vehicleAmount = 0;
    vehicleWithReserve = 0;
    vehicleWithoutReserve = 0;
    excelExport = {}

    constructor(data, type = null) {
        super();
        this.update(data, type);
    }

    update(data, type ) {
        super.update(data);
        let saleDateKey = Object.prototype.hasOwnProperty.call(data, 'dateVente') ? 'dateVente' : 'dateDebutVente';
        this.saleId = data['docEntryVente'];
        this.saleDate = moment(data[saleDateKey], 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.salesRoom = Object.prototype.hasOwnProperty.call(data, 'salleVenteNom') ? data['salleVenteNom'] : data['salleDeVente'];
        this.salesCity = data['salleVenteVille'];
        this.saleType = data['canalVente'];
        // For some odd reason, the api return a string for data['vehicules'], so the field is parse here.
        this.vehicleAmount = parseInt(data['vehicules']);
        this.vehicleWithReserve = data['vehiculesAvecReserve'];
        this.vehicleWithoutReserve = data['vehiculesSansReserve'];
        this.saleHour = data['heure'];

        // Need moment obj to sort data in DataTables
        this.saleDateMoment = stringToMoment(data[saleDateKey], 'YYYY-MM-DD');

        this.excelExport = {
            'Date de vente': this.saleDate,
            'Salle de vente': this.saleType === 'Electronique' ? this.salesRoom : this.salesCity,
            'Nombre de vehicules': this.vehicleAmount,
        }

        if (type === 'salesIncoming') {
            Object.assign(this.excelExport, {'Vehicules sans reserve': this.vehicleWithoutReserve})
        }
    }
}

