import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import displayStyle from '../assets/jss/displayStyle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import textStyle from '../assets/jss/textStyle';
import {CheckCircle} from '@material-ui/icons';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Typography, useTheme} from '@material-ui/core';
import {apiPost, fetchUserToken} from '../api/api';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';

export const loginStyles = makeStyles(theme => ({
    ...textStyle(theme),
    ...displayStyle(theme),
    logo: {
        maxWidth: '50%',
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    formWrapper: {
        padding: theme.spacing(2),
    },
    formControl: {
        width: '100%',
    },
    paper: {
        width: '100%',
        margin: theme.spacing(1),
    },
    singUpLink: {
        marginLeft: theme.spacing(1),
    },
}));

const Login = ({
    dispatch,
}) => {
    const {register, handleSubmit, errors} = useForm();
    const [error, setError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [successLostPassword, setSuccessLostPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const location = useLocation();
    const isLogin = location.pathname === '/login';
    const history = useHistory();

    const classes = loginStyles();


    const onSubmit = data => {
        setLoading(true);
        setError(false);
        setMessageError(false);

        localStorage.setItem('email', data['email']);

        if (isLogin) {
            fetchUserToken(data['email'], data['password'], dispatch)
                .then(() => {
                    setLoading(false);
                    history.push('/');
                })
                .catch(() => {
                    setLoading(false);
                    setError(true);
                });
        } else {
            apiPost('/lost_password', {email: data['email']})
                .then(resp => {
                    if (resp.valid) {
                        setSuccessLostPassword(true);
                    } else {
                        setError(true);
                        setMessageError(typeof resp.error === 'string' ? resp.error : 'Une erreur inconnue est survenue.');
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        }
    };


    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={6} className={classes.formContainer}>
                <Paper className={classes.paper} elevation={2}>
                    {loading ? <LinearProgress/> : null}
                    <div className={classes.formWrapper}>
                        {
                            successLostPassword
                                ?
                                <>
                                    <div style={{display: 'flex', justifyContent: 'center', color: theme.palette.success.main, marginBottom: theme.spacing(1)}}>
                                        <CheckCircle/>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography variant="body1" color="textPrimary">
                                            Un email de réinitialisation du mot de passe vous à été envoyé.
                                        </Typography>
                                    </div>
                                </>
                                :
                                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                                    <div className={classes.dFlex + ' ' + classes.justifyContentCenter}>
                                        <img src="/logo.png" className={classes.logo} alt="Encheres VO"/>
                                    </div>
                                    <FormControl className={classes.formControl}>
                                        {
                                            error
                                                ?
                                                <FormHelperText error className={classes.marginTop}>
                                                    {messageError ? messageError : 'Identifiants invalides'}
                                                </FormHelperText>
                                                : null
                                        }
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="Email"
                                            variant="outlined"
                                            disabled={loading}
                                            className={classes.marginTop}
                                            error={(Object.prototype.hasOwnProperty.call(errors, 'email') || error)}
                                            inputProps={{
                                                ref: register({
                                                    required: 'required',
                                                }),
                                            }}
                                        />
                                        {location.pathname === '/login' ?
                                            <TextField
                                                id="password"
                                                name="password"
                                                label="Mot de passe"
                                                type="password"
                                                variant="outlined"
                                                className={classes.marginTop}
                                                error={(Object.prototype.hasOwnProperty.call(errors, 'password') || error)}
                                                inputProps={{
                                                    ref: register({
                                                        required: 'required',
                                                    }),
                                                }}
                                            />
                                            : null}
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.marginTop}
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {isLogin ? 'Se connecter' : 'Réinitialiser mon mot de passe'}
                                        </Button>
                                    </FormControl>
                                    <Typography variant="body1" style={{paddingTop: '1rem'}}>
                                        {
                                            isLogin
                                                ?
                                                <Link to="/lost-password" onClick={() => setError(false)}>
                                                    Vous êtes nouveau ou vous avez oublié votre mot de passe ?
                                                </Link>
                                                :
                                                <Link to="/login" onClick={() => setError(false)}>
                                                    Retour
                                                </Link>
                                        }
                                    </Typography>
                                </form>
                        }
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}

Login.propTypes = {
    dispatch: PropTypes.func,
}

export default connect()(Login);