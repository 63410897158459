import moment from 'moment';
import {BaseModel} from 'sjs-base-model';
import {dateUsToDateObj} from '../core/utils';
import {stringToMoment} from '../core/dataTransformer';

export default class VehicleSale extends BaseModel {
    vehicleId = '';
    plateNumber = '';
    type = '';
    brand = '';
    commercialType = '';
    reservePrice = '';
    ownerDebtor = '';
    observation = '';
    createdDate = '';
    mandateDate = '';
    shippingDate = '';
    storagePark = '';
    status = '';
    subStatus = '';
    hasGreyCard = '';
    saleDate = '';
    order = 0;
    referenceSeller = '';
    mec = 0;
    cotePerso = 0;
    coteKilo = 0;
    estimationPrice = 0;
    color = '';
    percentCK = '';
    percentCP = '';
    doorsNumber = 0;
    docNum = '';
    personalQuotingReserveRatio = '';
    mileageQuotingReserveRatio = '';
    repairCost = '';

    constructor(data, listType = null) {
        super();
        this.update(data, listType);
    }

    update(data, listType = null) {
        super.update(data);
        this.vehicleId = data['docEntryVH'];
        this.plateNumber = data['immatriculation'];
        this.type = data['genre'];
        this.brand = data['marque'];
        this.commercialType = data['typeComm'];
        this.reservePrice = data['prixReserve'] === null ? '' : parseInt(data['prixReserve']);
        this.revocationPrice = data['prixRetrait'] === null ? '' : parseInt(data['prixRetrait']);
        this.order = data['numeroOrdre'];
        this.complementOrderNumber = data['numeroOrdreComplement'];
        this.tva = data['tVARecuperableLabel'];
        this.tvaRecoverable = data['tVARecuperableLabel'];
        this.kilomReal = data['kilomReel'];
        this.coteKilo = parseInt(data['coteKilo']);
        this.cotePerso = parseInt(data['cotePerso']);
        this.color = data['couleur'];
        this.referenceSeller = data['dossierRefVendeur'];
        this.estimationPrice = data['prixEstimation'];
        this.observation = data['observationVendeur'];

        // TODO : Provient de Logiwin.
        this.doorsNumber = data['nbPortes'];

        this.saleDate = dateUsToDateObj(data['dateDebutVente']);
        this.saleHour = data['venteHeure'];
        this.mec = data['datePMEC'] ? dateUsToDateObj(data['datePMEC']).toLocaleDateString() : data['datePMEC'];
        this.adjudicationPrice = data['prixAdjudication'];
        this.createdDate = moment(data['dateCreation'], 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.personalQuotingAdjudicationRatio = data['ratioAdjCK'];
        this.mileageQuotingAdjudicationRatio = data['ratioAdjCP'];
        this.personalQuotingReserveRatio = data['ratioResCP'];
        this.mileageQuotingReserveRatio = data['ratioResCK'];
        this.repairCost = data['fraisRemiseEnEtat'];
        this.baseData = data;

        // Need moment obj to sort data in DataTables
        this.registeredAtMoment = stringToMoment(data['datePMEC'], 'YYYY-MM-DD');
        this.createdAtMoment = stringToMoment(data['dateCreation'], 'YYYY-MM-DD');

        if (listType === 'SaleIncomingVehicles'){
            this.excelExport = {
                'Ord.' : this.order ? this.order + ' ' + this.complementOrderNumber : '',
                'Ref.': this.referenceSeller,
                'Immat.' : this.plateNumber,
                'Genre' : this.type,
                'Marque' : this.brand,
                'Type comm.' : this.commercialType,
                'Couleur': this.color,
                'Date creation': this.createdDate,
                'M.E.C': this.mec,
                'Km.' : this.kilomReal,
                'CP': this.cotePerso,
                'CK': this.coteKilo,
                'Retrait': this.revocationPrice,
                'Reserve': this.reservePrice,
                'Estimation': this.estimationPrice,
                '%Res/CP': this.personalQuotingReserveRatio ? this.personalQuotingReserveRatio + '%' : null,
                '%Res/CK': this.mileageQuotingReserveRatio ? this.mileageQuotingReserveRatio + '%' : null,
                'Obs.': this.observation,
                'FRE': this.repairCost,
                'Nb. portes': this.doorsNumber,
                'TVA': this.tva,
            }
        } else if (listType === 'SalePastVehicles'){
            this.excelExport = {
                'Ord.' : this.order ? this.order + ' ' + this.complementOrderNumber : '',
                'Ref.': this.referenceSeller,
                'Immat.' : this.plateNumber,
                'Genre' : this.type,
                'Marque' : this.brand,
                'Type comm.' : this.commercialType,
                'Couleur': this.color,
                'M.E.C': this.mec,
                'Km.' : this.kilomReal,
                'CP': this.cotePerso,
                'CK': this.coteKilo,
                'Reserve' : this.reservePrice,
                'Estimation': this.estimationPrice,
                'Adjudication' : this.adjudicationPrice,
                'Retrait' : this.revocationPrice,
                '%Adj/CP': this.personalQuotingAdjudicationRatio ? this.personalQuotingAdjudicationRatio + '%' : null,
                '%Adj/CK': this.mileageQuotingAdjudicationRatio ? this.mileageQuotingAdjudicationRatio + '%' : null,
                'Obs.': this.observation,
                'FRE': this.repairCost,
                'Nb. portes': this.doorsNumber,
                'TVA': this.tva,
            }
        }
    }
}



