import {apiPost} from './api';
import {getStore} from '../index';
import {
    setVehicles,
    setVehiclesBlocked,
    setVehiclesBlockedIsLoading, setVehiclesIsLoading,
    setVehiclesOutdated, setVehiclesOutdatedIsLoading,
    setVehiclesStock,
    setVehiclesStockIsLoading,
} from '../redux/vehicles';

// There is a switch/case in api-apps which use these const.
export const inComingVehicleList = 0;
export const outdatedVehicleList = 1;

export const fetchVehiclesData = (id, selectedUser) => {
    let data = selectedUser ? {cardCode: selectedUser['cardCode']} : null;

    getStore().dispatch(setVehiclesIsLoading(true));
    apiPost('/api/vehicles/sales/' + id + '/' + inComingVehicleList, data)
        .then(response => {
            getStore().dispatch(setVehicles(response));
        })
        .catch(e => {
            console.log(e);
        })
    ;
}

export const fetchOutdatedVehiclesData = (id, selectedUser) => {
    let data = selectedUser ? {cardCode: selectedUser['cardCode']} : null;

    getStore().dispatch(setVehiclesOutdatedIsLoading(true));
    apiPost('/api/vehicles/sales/' + id + '/' + outdatedVehicleList, data)
        .then(response => {
            getStore().dispatch(setVehiclesOutdated(response));
        })
        .catch(e => {
            console.log(e);
        })
    ;
}

export const updateReservePrice = (docEntry, reservePrice, orderNumber) => {
    return apiPost('/api/vehicle/reserve', {docEntry, reservePrice, orderNumber});
}

export const fetchVehiclesBlocked = selectedUser => {
    let data = selectedUser ? {cardCode: selectedUser['cardCode']} : null;

    getStore().dispatch(setVehiclesBlockedIsLoading(true));
    apiPost('/api/vehicles/blocked', data)
        .then(response => {
            getStore().dispatch(setVehiclesBlocked(response));
        })
        .catch(e => {
            console.log(e);
        })
    ;
}

export const fetchVehiclesStock = selectedUser => {
    let data = selectedUser ? {cardCode: selectedUser['cardCode']} : null;

    getStore().dispatch(setVehiclesStockIsLoading(true));
    apiPost('/api/vehicles/stock', data)
        .then(response => {
            getStore().dispatch(setVehiclesStock(response));
        })
        .catch(e => {
            console.log(e);
        })
    ;
}