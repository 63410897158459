import {createSelector, createSlice} from '@reduxjs/toolkit';
import {groupBy} from 'ramda';

export const utilSlice = createSlice({
    name: 'util',
    initialState: {
        servicesEmails: [],
        criticalError: false,
        servicesEmailsLoaded: false,
        servicesEmailsIsLoading: false,
    },
    reducers: {
        setServicesEmail: (state, action) => {
            return {
                ...state,
                servicesEmail: action.payload,
                servicesEmailsLoaded: true,
                servicesEmailsIsLoading: false,
            }
        },
        removeServicesEmail: (state) => {
            return {
                ...state,
                servicesEmail: [],
                servicesEmailsLoaded: false,
                servicesEmailsIsLoading: false,
            }
        },
        setCriticalError: (state, action) => {
            return {
                ...state,
                criticalError: action.payload,
            }
        },
        setServicesEmailsIsLoading: (state, action) => {
            return {
                ...state,
                servicesEmailsIsLoading: action.payload,
            }
        },
    },
});

export const {actions, reducer} = utilSlice;

export const {
    setServicesEmail,
    removeServicesEmail,
    setCriticalError,
    setServicesEmailsIsLoading,
} = actions;

export const servicesEmailsByServiceName = createSelector(
    state => state.utilReducer.servicesEmails,
    servicesEmail => {
        let grouped = groupBy(service => service['serviceName'], servicesEmail);

        return Object.keys(grouped).map(key => {
            return {
                serviceName: key,
                emails: grouped[key].map(service => service.email),
            };
        })
    }
)