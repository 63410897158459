import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {setBreadcrumbs} from '../redux/user';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        width: '50%',
    },
    link: {
        textDecoration: 'none',
        fontSize: '1rem',
    },
}));

const Breadcrumb = ({
    breadcrumbs = [],
    breadcrumb = false,
    dispatch,
}) => {
    const classes = useStyles();
    const history = useHistory();

    const buildBreadcrumbs = (removeIndex ) => {
        let newBreadcrumbs = [...breadcrumbs];
        newBreadcrumbs.splice(removeIndex, 1);
        return newBreadcrumbs;
    }

    const handleLink = (index) => {
        dispatch(setBreadcrumbs(buildBreadcrumbs(index)));
    }

    useEffect(() => {
        return () => {
            // Catch when user use previous in browser
            if (history.action === 'POP'){
                if (breadcrumb){
                    dispatch(setBreadcrumbs(buildBreadcrumbs(-1)));
                }
            }
        }
    }, [history])

    return (
        breadcrumb
            ? <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
                {breadcrumbs.map((breadcrumb, index) =>
                    <Link key={index} className={classes.link} onClick={() => handleLink(index)} to={breadcrumb.path}>
                        {breadcrumb.label}
                    </Link>)
                }
            </Breadcrumbs>
            : null
    );
}

Breadcrumb.propTypes = {
    breadcrumbs: PropTypes.array,
    breadcrumb: PropTypes.bool,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    breadcrumbs: state.userReducer.breadcrumbs,
    breadcrumb: state.userReducer.breadcrumb,
}))(Breadcrumb);