import {createSlice} from '@reduxjs/toolkit';
import {removeOutdatedSales, removeSales} from './sales';
import {removeVehiclesBlocked, removeVehiclesStock} from './vehicles';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        breadcrumbs: [],
        breadcrumb: false,
        prevLink: null,
        selectedUser: null,
        choicesUser: [],
        choicesUserLoaded: false,
    },
    reducers: {
        setChoicesUser: (state, action) => {
            return {
                ...state,
                choicesUser: action.payload,
                choicesUserLoaded: true,
                selectedUser: action?.payload[0]?action.payload[0]:null,
            }
        },
        setSelectedUser: (state, action) => {
            return {
                ...state,
                selectedUser: action.payload,
            }
        },
        removeSelectedUser: (state) => {
            return {
                ...state,
                selectedUser: null,
                choicesUserLoaded: false,
            }
        },
        setBreadcrumbs: (state, action) => {
            return {
                ...state,
                breadcrumbs: action.payload,
                breadcrumb: action.payload !== null,
            }
        },
        setPrevLink : (state, action) => {
            return {
                ...state,
                prevLink: action.payload,
            }
        },
    },
});

export const {actions, reducer} = userSlice;

export const {
    setChoicesUser,
    setSelectedUser,
    setBreadcrumbs,
    setPrevLink,
} = actions;

export const ROLE_SELLER_FAMILY = 'ROLE_SELLER_FAMILY';

export const selectUser = (selectedUser, history, location) => dispatch => {
    dispatch(setSelectedUser(selectedUser));
    dispatch(removeSales());
    dispatch(removeOutdatedSales());
    dispatch(removeVehiclesStock());
    dispatch(removeVehiclesBlocked());

    if (!['/list/sales/incoming', '/list/sales/past', '/list/vehicles/blocked', '/list/vehicles/stock'].includes(location.pathname)) {
        let returnPath = location.pathname.includes('incoming') ? '/list/sales/incoming' : '/list/sales/past';
        history.push(returnPath);
    }
}