import PropTypes from 'prop-types';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

// We can inject some CSS into the DOM.
const styles = {
    root: {
        color: '#144f5e',
        fontWeight: 'bold',
        opacity: 1,
    },
    selected: {
        color: '#ff6c39',
    },
};

function TabWarning(props) {
    const { classes, children, ...other } = props;

    return (
        <Tab classes={{
            root: classes.root,
            selected: classes.selected,
        }} {...other}>
            {children}
        </Tab>
    );
}

TabWarning.propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(TabWarning);