const paddingStyle = theme => {
    let classes = {};

    for (var i = 1; i < 6; i++) {
        classes['p' + i] = {
            padding: theme.spacing(i),
        }
        classes['m' + i] = {
            margin: theme.spacing(i),
        }
        classes['mt' + i] = {
            marginTop: theme.spacing(i),
        }
    }

    return (classes);
}

export default paddingStyle;