import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import displayStyle from '../assets/jss/displayStyle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import textStyle from '../assets/jss/textStyle';
import {CheckCircle} from '@material-ui/icons';
import {apiPost} from '../api/api';
import {testPasswordStrength} from '../core/utils';
import {useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';

export const loginStyles = makeStyles(theme => ({
    ...textStyle(theme),
    ...displayStyle(theme),
    logo: {
        maxWidth: '50%',
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    formWrapper: {
        padding: theme.spacing(2),
    },
    formControl: {
        width: '100%',
    },
    paper: {
        width: '100%',
        margin: theme.spacing(1),
    },
    singUpLink: {
        marginLeft: theme.spacing(1),
    },
    successIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.success.main,
        marginBottom: theme.spacing(1),
    },
}));

const ResetPassword = () => {
    const {register, handleSubmit, errors} = useForm();
    const [error, setError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [redirect, setRedirect] = useState(-1);
    const [userLoaded, setUserLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const {token} = useParams();

    const classes = loginStyles();

    // Validate token
    useEffect(() => {
        if (!userLoaded) {
            apiPost('/reset_password', {token: token})
                .then(resp => {
                    if (resp.valid) {
                        setLoading(false);
                        setUserLoaded(true);
                    } else {
                        history.push('/login')
                    }
                })
        }
        redirect > -1 && setTimeout(() => setRedirect(redirect - 1), 1000)
    }, [userLoaded, redirect])

    const onSubmit = data => {
        setLoading(true);

        if(!testPasswordStrength(data['password'])){
            setError(true)
            setMessageError('Le mot de passe doit contenir au moins 8 caractères avec une minuscule, une majuscule et 2 chiffres')
            setLoading(false)
            return
        }

        if (data['repeatPassword'] !== data['password']) {
            setError(true);
            setMessageError('Vos mots de passe ne correspondent pas.');
            setLoading(false);
            return;
        }


        apiPost('/reset_password', {token: token, password: data['password']})
            .then(resp => {
                setLoading(false)
                if (resp.valid) {
                    setRedirect(5);
                    setTimeout(() => {
                        history.push('/login')
                    }, 5000)
                } else {
                    setError(true);
                    setMessageError(typeof resp.error === 'string' ? resp.error : 'Une erreur inconnue est survenue')
                }
            })
    };


    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={6} className={classes.formContainer}>
                <Paper className={classes.paper} elevation={2}>
                    {loading ? <LinearProgress/> : null}
                    <div className={classes.formWrapper}>
                        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                            <div className={classes.dFlex + ' ' + classes.justifyContentCenter}>
                                <img src="/logo.png" className={classes.logo} alt="Encheres VO"/>
                            </div>
                            {redirect !== -1
                                ?
                                <>
                                    <div className={classes.successIconContainer}>
                                        <CheckCircle/>
                                    </div>
                                    <div className={classes.dFlexCenter}>
                                        <Typography variant="body1" color="textPrimary">
                                            Vous allez être redirigé vers la page de connexion
                                            dans {redirect} seconde{redirect !== 1 ? 's' : ''}...
                                        </Typography>
                                    </div>
                                </>
                                :
                                <FormControl className={classes.formControl}>
                                    {
                                        error
                                            ?
                                            <FormHelperText error className={classes.marginTop}>
                                                {messageError ? messageError : 'Identifiants invalides'}
                                            </FormHelperText>
                                            : null
                                    }
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="Mot de passe"
                                        type="password"
                                        variant="outlined"
                                        className={classes.marginTop}
                                        disabled={loading}
                                        error={(Object.prototype.hasOwnProperty.call(errors, 'email') || error)}
                                        inputProps={{
                                            ref: register({
                                                required: 'required',
                                            }),
                                        }}
                                    />
                                    <TextField
                                        id="repeatPassword"
                                        name="repeatPassword"
                                        type="password"
                                        label="Confirmez votre mot de passe"
                                        variant="outlined"
                                        className={classes.marginTop}
                                        error={error}
                                        disabled={loading}
                                        inputProps={{
                                            ref: register({
                                                required: 'required',
                                            }),
                                        }}
                                    />
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.marginTop}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Réinitialiser mon mot de passe
                                    </Button>
                                </FormControl>
                            }
                        </form>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ResetPassword;
