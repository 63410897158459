import moment from 'moment';
import {dateUsToDateObj} from './utils';

export const transformDataToDisplayableValue = (object, transformDate = false) => {
    Object.keys(object).forEach(key => {
        switch (object[key]) {
        case 'N':
        case false:
            object[key] = 'Non';
            break;
        case 'Y':
        case true:
            object[key] = 'Oui';
            break;
        case 'DONE':
            object[key] = 'Fait';
            break;
        case 'DOABLE':
            object[key] = 'À faire';
            break;
        case 'TODO':
            object[key] = 'À faire';
            break;
        case 'NO':
            object[key] = 'Non';
            break;
        default:
            break;
        }

        // Check if date is in YYYY-MM-DD format
        if (typeof object[key] === 'string' && object[key].match(/(\d{4})-(\d{2})-(\d{2})/) && transformDate) {
            object[key] = dateUsToDateObj(object[key]).toLocaleDateString()
        }
    });

    return object;
}

export const stringToMoment = (stringDate, format) => {
    if (stringDate) {
        return moment(stringDate, format);
    }

    return '';
}