import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import {Typography, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    value: {
        marginLeft: theme.spacing(1),
    },
    inputRoot: {
        fontWeight: 'bold',
        '&&&:before': {
            borderBottom: 'solid 1px !important',
        },
        '&&:after': {
            borderBottom: 'solid 1px !important',
        },
    },
    inputLabel: {
        transition: 'none',
        transform: 'none',
    },
}))

const FieldVehicle = ({
    input = false,
    label = '',
    value,
    gridItemSize = 1,
    children,
}) => {
    const classes = useStyles();

    return (
        input
            ? <Grid item xs={gridItemSize}>
                <TextField
                    label={label}
                    type="text"
                    value={value ?? ''}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        classes: {
                            root: classes.inputRoot,
                            disabled: classes.disabled,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputLabel,
                        },
                    }}
                />
            </Grid>
            : <Grid item xs={gridItemSize} className={classes.root}>
                <Typography variant="body1">
                    {label ? label + ' :' : ''}
                </Typography>
                {children
                    ? children
                    : <Typography variant="body1" className={classes.value}>
                        <strong>{value}</strong>
                    </Typography>
                }
            </Grid>
    );
}

FieldVehicle.propTypes = {
    input: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.any,
    gridItemSize: PropTypes.number,
    children: PropTypes.any,
}

export default FieldVehicle;